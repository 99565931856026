import { Injectable } from '@angular/core';
import { ConsumptionType } from '../../data-analytics/services/chart-data.service';
import { EnergyTableItemCategory } from './data.service';
import { EmsPowerBalanceDeliveredType, EmsPowerBalanceReceivedType } from './IotFeatures';

@Injectable({
    providedIn: 'root',
})
export class IconsService {
    getTableItemIcon(type: EnergyTableItemCategory, key: string) {
        const groupIcons = this.TABLE_ICONS[type];
        return `icons/${groupIcons[<keyof typeof groupIcons>key]}`;
    }

    getChartItemIcon(type: ConsumptionType, key: string) {
        const groupIcons = this.ENERGY_DIALOG_TABLE_ICONS[type];
        return `icons/${groupIcons[<keyof typeof groupIcons>key]}`;
    }

    static Icons = {
        BATTERY_MINUS: 'battery_discharging.svg',
        BATTERY_PLUS: 'battery_charging.svg',
        GRID_CONSUMPTION: 'grid_consumption.svg',
        GRID_FEED_IN: 'grid_feed-in.svg',
        HEATPUMP: 'heat_pump.svg',
        HOME: 'household.svg',
        PLUG: 'consumption.svg',
        SOLAR: 'pv.svg',
        WALLBOX: 'wallbox.svg',
        ELECTRICITY_PRODUCTION: 'production.svg',
        BATTERY_SOC: 'battery_soc.svg',
        CHARGING_STATION: 'wallbox.svg',
    };
    Icons = IconsService.Icons;

    TABLE_ICONS: {
        [EnergyTableItemCategory.DELIVERED]: { [key in EmsPowerBalanceDeliveredType]: string };
        [EnergyTableItemCategory.RECEIVED]: { [key in EmsPowerBalanceReceivedType]: string };
    } = {
        [EnergyTableItemCategory.DELIVERED]: {
            Accumulator: this.Icons.BATTERY_MINUS,
            Grid: this.Icons.GRID_FEED_IN,
            Photovoltaic: this.Icons.SOLAR,
        },
        [EnergyTableItemCategory.RECEIVED]: {
            Accumulator: this.Icons.BATTERY_PLUS,
            Grid: this.Icons.GRID_CONSUMPTION,
            Heatpump: this.Icons.HEATPUMP,
            Household: this.Icons.HOME,
            VehicleChargingStation: this.Icons.WALLBOX,
        },
    };

    ENERGY_DIALOG_TABLE_ICONS = {
        [ConsumptionType.AUTARKY]: {
            directConsumption: this.Icons.PLUG,
            batteryDischarge: this.Icons.BATTERY_MINUS,
            grid: this.Icons.GRID_CONSUMPTION,
        },
        [ConsumptionType.SELF_CONSUMPTION]: {
            directConsumption: this.Icons.PLUG,
            batteryCharge: this.Icons.BATTERY_MINUS,
            grid: this.Icons.GRID_FEED_IN,
        },
    };
}
