<div class="flex-container body">
    <div class="flex-row flex-fill align-content-between">
        <div class="flex-child" *ngIf="status === 'default'; else skeleton">
            <div class="flex-row align-items-center">
                <div class="flex-child-img">
                    <svg-icon
                        data-testid="analysis-environment-img"
                        [src]="assetService.getAssetPath('images/environment.svg')"
                        [svgStyle]="{ maxHeight: '130px' }"
                    />
                </div>
                <div class="flex-child-p" *ngIf="environmentStatistics">
                    <p
                        data-testid="analysis-environment-component-text"
                        class="no-magic-padding"
                        [innerHtml]="'ENERGY_COCKPIT.ENVIRONMENT_COMPONENT.TEXT' | translate: environmentStatistics"
                    ></p>
                </div>
            </div>
        </div>

        <div class="flex-child flex-col justify-content-center">
            <button
                data-testid="analysis-environment-button"
                mat-button
                class="flex-row-reverse custom-button"
                (click)="openDialog()"
                [disabled]="status !== 'default'"
            >
                <span>{{ 'ENERGY_COCKPIT.ENVIRONMENT_COMPONENT.BUTTON_LABEL' | translate }}</span>
                <mat-icon class="chevron-icon" svgIcon="chevron_right"></mat-icon>
            </button>
        </div>
    </div>
</div>

<ng-template #skeleton>
    <div class="flex-child flex-col-5 d-flex justify-content-center">
        <ngx-skeleton-loader [appearance]="'custom-content'">
            <img class="placeholder-img" [src]="assetService.getAssetPath('images/environment_placeholder.svg')" />
        </ngx-skeleton-loader>
    </div>
    <div class="flex-child flex-col-6 align-self-center">
        <ngx-skeleton-loader
            [animation]="'progress-dark'"
            [appearance]="'line'"
            [count]="3"
            [theme]="{ width: '100%', height: '16px', margin: '0 4px' }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
