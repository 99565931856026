import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateRangeMode } from '../../services/DateRangeMode';
import {
    ParameterRootSection,
    ParameterSection,
    ParameterSectionType,
    ParameterSetting,
    ParametersService,
} from '../../services/parameters.service';
import { TimeSeriesProperties } from 'src/app/modules/shared/services/iot-backend/iot-backend.service';

export interface ParametersSelectDialogOutput {
    selectedSection: ParameterSectionType | undefined;
    selectedFeatures: TimeSeriesProperties[];
}

export interface ParametersSelectDialogOptions extends ParametersSelectDialogOutput {
    rootSections: ParameterRootSection[];
    rangeMode: DateRangeMode;
}

@Component({
    selector: 'app-parameters-select-dialog',
    templateUrl: './parameters-select-dialog.component.html',
    styleUrls: ['./parameters-select-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None, // required to remove ripples
})
export class ParametersSelectDialogComponent {
    selectedSectionType: ParameterSectionType | undefined;
    showRadioButtons = true;
    rootSections: RootSectionsWithParameters;
    selectedFeatures: TimeSeriesProperties[] = [];
    constructor(
        private dialogRef: MatDialogRef<ParametersSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: ParametersSelectDialogOptions,
        public parameters: ParametersService,
    ) {
        this.rootSections = data.rootSections.map((rootSection) => ({
            rootSection,
            sections: this.parameters.getSectionsByRootSection(rootSection).map((section) => section),
        }));
        const allSectionsToDisplay = this.rootSections.flatMap((rootSection) => rootSection.sections);
        this.showRadioButtons = allSectionsToDisplay.length > 1;

        let selectedSection = allSectionsToDisplay.find((section) => section.type === data.selectedSection);
        if (selectedSection && data.selectedSection) {
            this.selectedSectionType = data.selectedSection;
            this.selectAllSectionParameters(this.selectedSectionType, data.selectedFeatures);
        }

        const firstSection = allSectionsToDisplay[0];
        if (!this.selectedSectionType) {
            this.selectedSectionType = firstSection.type;
            selectedSection = firstSection;
            this.selectAllSectionParameters(this.selectedSectionType);
        }
    }

    get selectionStats() {
        if (!this.selectedSectionType) {
            return;
        }
        const activeSection = this.parameters.getSection(this.selectedSectionType);

        const available = activeSection?.parameters.filter((parameter) =>
            this.canParameterBeSelected(parameter),
        ).length;

        const selected = this.selectedFeatures.length;

        return { selected, available };
    }

    changeParameterSelection(parameter: ParameterSetting, selected: boolean) {
        if (selected) {
            this.selectedFeatures.push(parameter.featureName);
            return;
        }
        this.selectedFeatures = this.selectedFeatures.filter((p) => p !== parameter.featureName);
    }
    isSelected(parameter: ParameterSetting, section: ParameterSection) {
        if (section.type !== this.selectedSectionType) {
            return false;
        }
        return this.canParameterBeSelected(parameter) && this.selectedFeatures.includes(parameter.featureName);
    }
    canParameterBeSelected(parameter: ParameterSetting) {
        return !(parameter.dayViewOnly && this.data.rangeMode !== DateRangeMode.DAY);
    }

    save() {
        this.dialogRef.close(<ParametersSelectDialogOutput>{
            selectedSection: this.selectedSectionType,
            selectedFeatures: this.selectedFeatures,
        });
    }

    selectedSectionChange(section: ParameterSectionType) {
        this.selectAllSectionParameters(section);
    }

    private selectAllSectionParameters(section: ParameterSectionType, existingSelection?: TimeSeriesProperties[]) {
        this.selectedFeatures =
            this.parameters
                .getSection(section)
                .parameters.filter(
                    (parameter) =>
                        this.canParameterBeSelected(parameter) &&
                        (!existingSelection || existingSelection.includes(parameter.featureName)),
                )
                .map((parameter) => parameter.featureName) || [];
    }
}

export type RootSectionsWithParameters = {
    rootSection: ParameterRootSection;
    sections: ParameterSection[];
}[];
