import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconsService } from '../../shared/services/icons.service';
import { AllTimeSeriesProperties, TimeSeriesResponse } from '../../shared/services/iot-backend/iot-backend.service';

@Injectable({
    providedIn: 'root',
    deps: [],
})
export class ChartDataService {
    public readonly CHART_FEATURES = <const>{
        ENERGY_PRODUCTION_TO_CONSUMPTION: 'ems.energy.production.to.consumption',
        ENERGY_STORAGE_TO_CONSUMPTION: 'ems.energy.storage.to.consumption',
        ENERGY_GRID_CONSUMPTION: 'ems.energy.grid.to.consumption',
        ENERGY_PRODUCTION_TO_STORAGE: 'ems.energy.production.to.storage',
        ENERGY_GRID_FEED_IN: 'ems.energy.grid.feed-in',
        KPI_AUTARKY: 'ems.kpi.autarky',
        KPI_SELF_CONSUMPTION: 'ems.kpi.self-consumption',
        CARBON_EMISSIONS: 'ems.carbon.emissions',
        CARBON_SAVINGS: 'ems.carbon.savings',
        CARBON_TREES: 'ems.carbon.trees',
        CARBON_CAR_DISTANCE: 'ems.carbon.car.distance',
    };

    constructor(
        private iconsService: IconsService,
        private translate: TranslateService,
    ) {}

    public getChartData(timeSeries: TimeSeriesResponse): EnergyChartItem[] {
        const featuresMappings: FeaturesToChartItemsMappings = {
            [ConsumptionType.AUTARKY]: {
                directConsumption: this.CHART_FEATURES.ENERGY_PRODUCTION_TO_CONSUMPTION,
                batteryDischarge: this.CHART_FEATURES.ENERGY_STORAGE_TO_CONSUMPTION,
                grid: this.CHART_FEATURES.ENERGY_GRID_CONSUMPTION,
            },
            [ConsumptionType.SELF_CONSUMPTION]: {
                directConsumption: this.CHART_FEATURES.ENERGY_PRODUCTION_TO_CONSUMPTION,
                batteryCharge: this.CHART_FEATURES.ENERGY_PRODUCTION_TO_STORAGE,
                grid: this.CHART_FEATURES.ENERGY_GRID_FEED_IN,
            },
        };

        return Object.entries(featuresMappings).flatMap(([type, v]) => {
            const groupItems = Object.entries(v).map(([key, feature]) => {
                const consumptionType = <ConsumptionType>type;
                return {
                    ...this.getTimeSeriesValueItem(timeSeries, feature),
                    icon: this.iconsService.getChartItemIcon(consumptionType, key),
                    type: key,
                    consumptionType,
                };
            });
            const groupTotal = groupItems.reduce((total, item) => <number>total + <number>item.value, 0);
            return groupItems.map((item) => ({
                ...item,
                percentage: (item.value / groupTotal) * 100,
                additionalInfo: [],
            }));
        });
    }

    public getEnvironmentStatistics(timeSeries: TimeSeriesResponse): EnvironmentStatistics {
        return {
            co2Emission: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.CARBON_EMISSIONS, {
                roundValue: true,
            }),
            co2Savings: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.CARBON_SAVINGS, {
                roundValue: true,
            }),
            treesSaved: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.CARBON_TREES, { roundValue: true }),
            carTravel: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.CARBON_CAR_DISTANCE, {
                roundValue: true,
            }),
        };
    }

    public getEnergyKpiValues(timeSeries: TimeSeriesResponse) {
        return {
            autarky: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.KPI_AUTARKY, { roundValue: true })
                .value,
            selfConsumption: this.getTimeSeriesValueItem(timeSeries, this.CHART_FEATURES.KPI_SELF_CONSUMPTION, {
                roundValue: true,
            }).value,
        };
    }
    private getTimeSeriesValueItem(
        timeSeries: TimeSeriesResponse,
        feature: (typeof AllTimeSeriesProperties)[number],
        { roundValue }: { roundValue?: boolean } = { roundValue: false },
    ): ValueItemWithUnit {
        if (!timeSeries.summary_properties[feature]) {
            return { value: NaN, unit: 'n/a' };
        }
        return {
            value: roundValue ? this.round(timeSeries.data.summary[feature]) : timeSeries.data.summary[feature],
            unit: this.translate.instant(
                `DATA_ANALYTICS.UNITS.${timeSeries.summary_properties[feature].replace('.', '')}`,
            ),
        };
    }

    private round(value: Number | undefined) {
        if (typeof value === 'number') {
            return Math.round(value);
        }
        return 0;
    }
}

export interface ValueItemWithUnit {
    value: number;
    unit: string;
}

export interface EnergyChartItem {
    value: number;
    unit: string;
    icon: string;
    type: string;
    consumptionType: ConsumptionType;
    percentage: number;
    additionalInfo: string[];
}

export enum ConsumptionType {
    AUTARKY = 'autarky',
    SELF_CONSUMPTION = 'self_consumption',
}

export type FeaturesToChartItemsMappings = {
    [key in ConsumptionType]: {
        [key: string]: (typeof AllTimeSeriesProperties)[number];
        grid: (typeof AllTimeSeriesProperties)[number];
    };
};

export interface EnvironmentStatistics {
    co2Emission: ValueItemWithUnit;
    co2Savings: ValueItemWithUnit;
    treesSaved: ValueItemWithUnit;
    carTravel: ValueItemWithUnit;
}
