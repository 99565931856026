<div class="headline-section body">
    <div class="d-flex flex-column pt-1">
        <h2 data-testid="analysis-energy-dialog-title" class="mb-0">
            {{ 'ENERGY_COCKPIT.ENERGY_DIALOG.TITLE.' + data.type | translate }}
        </h2>
    </div>
    <button data-testid="analysis-energy-dialog-close-button" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="close" class="close-icon"></mat-icon>
    </button>
</div>

<p data-testid="analysis-energy-dialog-summary" class="summary body">
    {{
        'ENERGY_COCKPIT.ENERGY_DIALOG.SUMMARY.' + data.type
            | translate
                : { total: (energySummary.total | formatPowerUnits), self: (energySummary.self | formatPowerUnits) }
    }}
</p>

<div mat-dialog-content class="content">
    <div class="flex-row">
        <div class="flex-child flex-col-6 flex-col-lg-6 d-flex justify-content-center">
            <energy-circle-chart
                data-testid="analysis-energy-dialog-circle-chart"
                class="circle-chart"
                [chartHeight]="220"
                [series]="data.series"
                [colors]="
                    data.type === 'autarky' ? ['#2CC84D', '#166427', '#EDEDED'] : ['#FBC640', '#905500', '#EDEDED']
                "
                status="default"
            ></energy-circle-chart>
        </div>
        <div class="flex-child flex-col-6 flex-col-lg-6">
            <p data-testid="analysis-energy-dialog-overview-self-title" class="mb-1 title text-uppercase">
                {{ 'ENERGY_COCKPIT.ENERGY_DIALOG.OVERVIEW.SELF.' + data.type | translate }}
            </p>
            <div class="flex-child mb-3" [ngClass]="data.type">
                <div class="flex-container" *ngFor="let item of energyItems; let i = index">
                    <app-energy-item [item]="item" [type]="data.type + ' spacer-bg-' + i"></app-energy-item>
                </div>
            </div>
            <p data-testid="analysis-energy-dialog-overview-grid-title" class="mb-1 title text-uppercase">
                {{ 'ENERGY_COCKPIT.ENERGY_DIALOG.OVERVIEW.GRID.' + data.type | translate }}
            </p>
            <div class="flex-child mb-3" [ngClass]="data.type">
                <div class="flex-container" *ngFor="let item of gridItems; let i = index">
                    <app-energy-item [item]="item" [type]="data.type + ' spacer-bg-' + i"></app-energy-item>
                </div>
            </div>
        </div>
    </div>
</div>
