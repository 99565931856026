<div class="simulation-control">
    <mat-card style="max-width: 900px">
        <mat-card-header>
            <mat-card-title>Simulation control</mat-card-title>
            <mat-card-subtitle
                >Change this setting and they will be emitted as fake live-update events to simulation<br />
                Set value to "0" to remove given module form home</mat-card-subtitle
            >
        </mat-card-header>
        <mat-card-content>
            <div class="simulation-control">
                <div *ngFor="let direction of ['delivered', 'received']">
                    <h3>{{ direction }}</h3>
                    <div *ngFor="let item of simulationState">
                        <ng-container *ngIf="item.direction === direction">
                            <mat-checkbox
                                [attr.data-testid]="item.direction + item.name + '-checkbox'"
                                [(ngModel)]="item.enabled"
                            >
                                <mat-form-field>
                                    <mat-label>{{ item.name }}</mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        step="100"
                                        [disabled]="!item.enabled"
                                        [(ngModel)]="item.value"
                                        [attr.data-testid]="item.direction + item.name"
                                    />
                                    <span matSuffix> Watt &nbsp;</span>
                                </mat-form-field>
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </div>

                <div>
                    <h3>Statistics</h3>
                    <mat-form-field>
                        <mat-label>Autarky</mat-label>
                        <input
                            matInput
                            type="number"
                            [(ngModel)]="statistics.autarky"
                            data-testid="simulator-autarky"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Self consumption</mat-label>
                        <input
                            matInput
                            type="number"
                            [(ngModel)]="statistics.selfConsumption"
                            data-testid="simulator-self-consumption"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>battery charge</mat-label>
                        <input
                            matInput
                            type="number"
                            [(ngModel)]="statistics.batteryCharge"
                            data-testid="simulator-battery-charge"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Analytics maximum value</mat-label>
                        <input
                            matInput
                            type="number"
                            [(ngModel)]="statistics.maximumAnalyticsValue"
                            data-testid="simulator-analytics-max-value"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Feature to hide form analytics response</mat-label>
                        <input matInput type="string" [(ngModel)]="statistics.hideFeature" />
                        <mat-hint>SOC=ess.stateOfCharge/value </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
