import { Component, Input, OnInit } from '@angular/core';
import {
    ApexChart,
    ApexDataLabels,
    ApexLegend,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexResponsive,
    ApexStates,
    ApexTooltip,
} from 'ng-apexcharts';
import { AssetService } from '../../../shared/services/asset.service';
import { EnergyChartItem } from '../../services/chart-data.service';

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    tooltip: ApexTooltip;
    responsive: ApexResponsive[];
    legend: ApexLegend;
    states: ApexStates;
    dataLabels: ApexDataLabels;
    labels: never;
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: 'energy-circle-chart',
    templateUrl: './energy-circle-chart.component.html',
    styleUrls: ['./energy-circle-chart.component.scss'],
})
export class EnergyCircleChartComponent implements OnInit {
    public chartOptions!: Partial<ChartOptions>;
    public chartSeries: ApexNonAxisChartSeries = [];
    public chartValue = '';
    public currentSeries?: EnergyChartItem[];
    @Input() chartHeight?: number;
    @Input() textLabel: string | undefined;
    @Input() status: 'default' | 'pending' | 'error' = 'pending';
    @Input() colors!: string[];

    @Input()
    set value(value: number) {
        if (!value) {
            value = 0;
        }
        this.chartSeries = [value, 100 - value];
        this.chartValue = `${value}%`;
    }

    @Input()
    set series(series: EnergyChartItem[]) {
        this.currentSeries = series;
        this.setupChart();
    }

    constructor(public assetService: AssetService) {}

    ngOnInit(): void {
        this.chartOptions = this.createChartOptions();
        this.setupChart();
    }

    setupChart(): void {
        if (this.currentSeries && this.chartOptions) {
            const sum = this.currentSeries
                .filter((item) => item.type !== 'grid')
                .map((item) => item.percentage)
                .reduce((partialSum, a) => partialSum + a, 0);
            this.chartValue = `${sum.toFixed(0)}%`;
            this.chartSeries = this.currentSeries.map((item) => item.percentage);
        }
    }

    createChartOptions(): Partial<ChartOptions> {
        return {
            chart: {
                type: 'donut',
                height: this.chartHeight ? this.chartHeight : 175,
                animations: {
                    enabled: true,
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    },
                },
                active: {
                    filter: {
                        type: 'none',
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: '75%',
                        labels: {
                            show: true,
                            value: {
                                offsetY: -6,
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: '',
                                formatter: () => this.chartValue,
                            },
                        },
                    },
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        };
    }
}
