<div class="controls">
    <div class="date-options">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'DATA_ANALYTICS.CONTROLS.RANGE_MODE.TITLE' | translate }}</mat-label>
            <mat-select data-testid="range-selection" [formControl]="rangeModeFormControl">
                <mat-option data-testid="day-range-option" [value]="RangeMode.DAY">{{
                    'DATA_ANALYTICS.CONTROLS.RANGE_MODE.DAY' | translate
                }}</mat-option>
                <mat-option data-testid="month-range-option" [value]="RangeMode.MONTH">{{
                    'DATA_ANALYTICS.CONTROLS.RANGE_MODE.MONTH' | translate
                }}</mat-option>
                <mat-option data-testid="year-range-option" [value]="RangeMode.YEAR">{{
                    'DATA_ANALYTICS.CONTROLS.RANGE_MODE.YEAR' | translate
                }}</mat-option>
                <mat-option data-testid="total-range-option" [value]="RangeMode.ALL">{{
                    'DATA_ANALYTICS.CONTROLS.RANGE_MODE.ALL' | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
        <vi-date-picker
            data-testid="date-picker-component"
            [disabled]="(loading$ | async) || false"
            [rangeMode]="rangeModeFormControl.value!"
            (selectedDate)="dateChanged($event)"
            *ngIf="rangeModeFormControl.value !== RangeMode.ALL"
        ></vi-date-picker>
    </div>
    <div class="content-options">
        <button
            data-testid="overview-button"
            mat-raised-button
            [color]="isEnergyBalanceModeSelected ? 'primary' : 'accent'"
            [disabled]="loading$ | async"
            (click)="openOverviewModal()"
        >
            {{ 'DATA_ANALYTICS.CONTROLS.OVERVIEW' | translate }}
        </button>
        <button
            data-testid="detailed-analysis-button"
            mat-raised-button
            [color]="!isEnergyBalanceModeSelected ? 'primary' : 'accent'"
            [disabled]="loading$ | async"
            (click)="openDetailedAnalysisModal()"
        >
            {{ 'DATA_ANALYTICS.CONTROLS.DETAILED_ANALYSYS' | translate }}
        </button>
    </div>
</div>
