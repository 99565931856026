import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { basicEnergyCircleChartComponent as BasicEnergyCircleChartComponent } from './components/basic-energy-circle-chart/basic-energy-circle-chart.component';
import { EnergyItemComponent } from './components/energy-item/energy-item.component';
import { HouseAnimationComponent } from './components/house-animation/house-animation.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FormatUnitsPipe } from './pipes/format-units.pipe';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        BasicEnergyCircleChartComponent,
        NoDataComponent,
        FormatUnitsPipe,
        HouseAnimationComponent,
        EnergyItemComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        TranslateModule.forChild(),
        LottieModule.forRoot({ player: playerFactory }),
        NgxSkeletonLoaderModule,
        AngularSvgIconModule,
    ],
    providers: [],
    exports: [
        BasicEnergyCircleChartComponent,
        NoDataComponent,
        HouseAnimationComponent,
        FormatUnitsPipe,
        EnergyItemComponent,
    ],
})
export class SharedModule {}
