import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { DateRangeMode } from '../../services/DateRangeMode';
import { ParameterRootSection, ParameterSectionType, ParametersService } from '../../services/parameters.service';
import {
    ParametersSelectDialogComponent,
    ParametersSelectDialogOptions,
    ParametersSelectDialogOutput,
} from '../parameters-select-dialog/parameters-select-dialog.component';
import { TimeSeriesProperties } from 'src/app/modules/shared/services/iot-backend/iot-backend.service';

@Component({
    selector: 'vi-data-analytics-control',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
    RangeMode = DateRangeMode;
    selectedSection: ParameterSectionType | undefined = ParameterSectionType.ENERGY_BALANCE;
    selectedFeatures$ = new BehaviorSubject<TimeSeriesProperties[]>(
        this.parametersService.getSection(ParameterSectionType.ENERGY_BALANCE).parameters.map((p) => p.featureName),
    );
    selectedDate$ = new BehaviorSubject<Date>(new Date());
    rangeModeFormControl = new FormControl<DateRangeMode>(DateRangeMode.MONTH);

    loading$ = new BehaviorSubject(true);
    @Input() set loading(loading: boolean) {
        this.loading$.next(loading);
    }

    @Output() settingsChanged = new EventEmitter<AnalyticsSettings>();
    constructor(
        private dialog: MatDialog,
        private parametersService: ParametersService,
    ) {}

    ngOnInit(): void {
        this.loading$.subscribe((loading) => {
            if (loading) {
                this.rangeModeFormControl.disable();
                return;
            }
            this.rangeModeFormControl.enable();
        });

        combineLatest([
            this.rangeModeFormControl.valueChanges.pipe(
                startWith(this.rangeModeFormControl.value),
                distinctUntilChanged(),
            ),
            this.selectedDate$.pipe(startWith(this.selectedDate$.value)),
            this.selectedFeatures$,
        ])
            .pipe(
                filter(([rangeMode]) => !!rangeMode),
                map(
                    ([rangeMode, selectedDate, selectedFeatures]) =>
                        <AnalyticsSettings>{
                            rangeMode,
                            selectedDate,
                            selectedFeatures,
                            selectedSection: this.selectedSection,
                        },
                ),
            )
            .subscribe(this.settingsChanged);
    }

    dateChanged(date: Date) {
        this.selectedDate$.next(date);
    }
    openOverviewModal() {
        this.openParametersSelectModal([ParameterRootSection.ENERGY_BALANCE]);
    }

    openDetailedAnalysisModal() {
        this.openParametersSelectModal([ParameterRootSection.CONSUMPTION_VIEW, ParameterRootSection.PRODUCTION_VIEW]);
    }
    openParametersSelectModal(rootSections: ParameterRootSection[]) {
        this.dialog
            .open(ParametersSelectDialogComponent, {
                data: <ParametersSelectDialogOptions>{
                    rootSections,
                    rangeMode: this.rangeModeFormControl.value,
                    selectedFeatures: this.selectedFeatures$.value,
                    selectedSection: this.selectedSection,
                },
                autoFocus: false,
            })
            .afterClosed()
            .subscribe((result: ParametersSelectDialogOutput) => {
                if (!result) {
                    return;
                }
                this.selectedSection = result.selectedSection;
                this.selectedFeatures$.next(result.selectedFeatures);
            });
    }

    get isEnergyBalanceModeSelected() {
        return this.selectedSection === ParameterSectionType.ENERGY_BALANCE;
    }
}

export interface AnalyticsSettings {
    rangeMode: DateRangeMode;
    selectedDate: Date;
    selectedFeatures: TimeSeriesProperties[];
    selectedSection: ParameterSectionType;
}
