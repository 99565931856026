import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConsumptionType, EnergyChartItem } from '../../../data-analytics/services/chart-data.service';
import { EnergyDialogComponent } from '../energy-dialog/energy-dialog.component';

@Component({
    selector: 'energy-dialog-open-button',
    templateUrl: './energy-dialog-open-button.component.html',
    styleUrls: ['./energy-dialog-open-button.component.scss'],
})
export class EnergyDialogOpenButtonComponent {
    @Input() series: EnergyChartItem[] | undefined;
    @Input() type!: ConsumptionType;
    @Input() status: 'default' | 'pending' | 'error' = 'pending';

    constructor(private dialog: MatDialog) {}
    openEnergyDialog() {
        if (!this.series) {
            return;
        }
        this.dialog.open(EnergyDialogComponent, {
            data: {
                series: this.series,
                type: this.type,
            },
            autoFocus: false,
            maxWidth: '790px',
        });
    }
}
