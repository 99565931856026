export enum EnergyCockpitStatus {
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    NO_DATA = 'NO_DATA',
    READY = 'READY',
}

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

export type LoadingStatus = 'default' | 'pending' | 'error';
