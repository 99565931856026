import { Component, Input } from '@angular/core';
import { ApexNonAxisChartSeries } from 'ng-apexcharts';
import { BehaviorSubject, delay, map } from 'rxjs';
import { AssetService } from '../../services/asset.service';

@Component({
    selector: 'basic-energy-circle-chart',
    templateUrl: './basic-energy-circle-chart.component.html',
    styleUrls: ['./basic-energy-circle-chart.component.scss'],
})
export class basicEnergyCircleChartComponent {
    public chartSeries: ApexNonAxisChartSeries = [];

    @Input() textLabel: string | undefined;
    @Input() status: 'default' | 'pending' | 'error' = 'pending';
    @Input() color!: string;
    @Input() set value(value: number) {
        this.value$.next(value);
    }

    value$ = new BehaviorSubject(0);
    chartItemGradientAngle$ = this.value$.pipe(
        delay(10),
        map((value) => `${value * 3.6}deg`),
    );
    constructor(public assetService: AssetService) {}
}
