import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconsService } from '../../shared/services/icons.service';
import {
    TimeSeriesProperties,
    TimeSeriesRecordFeatures,
    TimeSeriesResponse,
} from '../../shared/services/iot-backend/iot-backend.service';
import { DateRangeMode } from './DateRangeMode';

@Injectable({
    providedIn: 'root',
})
export class ParametersService {
    constructor(private t: TranslateService) {}
    private sections: ParameterSection[] = [
        {
            type: ParameterSectionType.ENERGY_BALANCE,
            rootSection: ParameterRootSection.ENERGY_BALANCE,
            parameters: [
                {
                    featureName: 'ems.energy.production.total',
                    axisColor: '#FBC640',
                    icon: IconsService.Icons.ELECTRICITY_PRODUCTION,
                    defaultUnit: 'kWh',
                },
                {
                    featureName: 'ems.energy.consumption.total',
                    axisColor: '#2CC84D',
                    icon: IconsService.Icons.PLUG,
                    defaultUnit: 'kWh',
                },
                {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    featureName: 'ess.stateOfCharge/value',
                    axisColor: '#DEDEDE',
                    strokeColor: '#333333',
                    icon: IconsService.Icons.BATTERY_SOC,
                    dayViewOnly: true,
                    axisDashed: true,
                    defaultUnit: 'percentage',
                },
            ],
        },
        {
            type: ParameterSectionType.ENERGY_SOURCES,
            rootSection: ParameterRootSection.CONSUMPTION_VIEW,
            summaryParameter: {
                featureName: 'ems.energy.consumption.total',
            },

            parameters: [
                {
                    featureName: 'ems.energy.grid.to.consumption',
                    axisColor: '#545454',
                    icon: IconsService.Icons.GRID_CONSUMPTION,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.storage.discharge',
                    axisColor: '#2CC84D',
                    icon: IconsService.Icons.BATTERY_MINUS,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.consumption.household',
                    axisColor: '#FBC640',
                    icon: IconsService.Icons.PLUG,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ess.stateOfCharge/value',
                    axisColor: '#333333',
                    icon: IconsService.Icons.BATTERY_SOC,
                    dayViewOnly: true,
                    axisDashed: true,
                    defaultUnit: 'percentage',
                },
            ],
        },
        {
            type: ParameterSectionType.ENERGY_CONSUMPTION,
            rootSection: ParameterRootSection.CONSUMPTION_VIEW,
            parameters: [
                {
                    featureName: 'ems.energy.consumption.heat-pump',
                    axisColor: '#2CC84D',
                    icon: IconsService.Icons.HEATPUMP,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.consumption.household',
                    axisColor: '#96E4A6',
                    icon: IconsService.Icons.HOME,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.consumption.vcs',
                    axisColor: '#166427',
                    icon: IconsService.Icons.CHARGING_STATION,
                    defaultUnit: 'kW',
                },
                {
                    label: 'Battery charge',
                    featureName: 'ems.energy.storage.charge',
                    axisColor: '#2CC84D',
                    icon: IconsService.Icons.BATTERY_PLUS,
                    defaultUnit: 'kW',
                },
            ],
        },
        {
            type: ParameterSectionType.ENERGY_PRODUCTION,
            rootSection: ParameterRootSection.PRODUCTION_VIEW,
            summaryParameter: {
                featureName: 'ems.energy.production.total',
            },

            parameters: [
                {
                    featureName: 'ems.energy.grid.feed-in',
                    axisColor: '#545454',
                    icon: IconsService.Icons.GRID_FEED_IN,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.production.to.consumption',
                    axisColor: '#FBC640',
                    icon: IconsService.Icons.PLUG,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ems.energy.production.to.storage',
                    axisColor: '#2CC84D',
                    icon: IconsService.Icons.BATTERY_MINUS,
                    defaultUnit: 'kW',
                },
                {
                    featureName: 'ess.stateOfCharge/value',
                    axisColor: '#333333',
                    icon: IconsService.Icons.BATTERY_SOC,
                    dayViewOnly: true,
                    axisDashed: true,
                    defaultUnit: 'percentage',
                },
            ],
        },
    ];

    getSectionsByRootSection(rootSection: ParameterRootSection): ParameterSection[] {
        return this.sections
            .filter((section) => section.rootSection === rootSection)
            .map((section) => this.translateSection(section));
    }

    getSection(sectionType: ParameterSectionType): ParameterSection {
        return this.translateSection(<ParameterSection>this.sections.find((section) => section.type === sectionType));
    }

    mergeSectionWithTimeSeriesData(
        sectionType: ParameterSectionType,
        timeSeriesResponse: TimeSeriesResponse,
        dateRange: DateRange,
    ): ParameterSectionWithData | WrongDataError {
        if (!timeSeriesResponse) {
            return { error: 'NOT_ENOUGH_DATA' };
        }

        const section = this.getSection(sectionType);
        const timeSeries = timeSeriesResponse.data.timeseries.filter((record) => !this.isNullRecord(record));

        if (!timeSeries.length) {
            return { error: 'NOT_ENOUGH_DATA' };
        }

        const isOneDayView = dateRange.rangeMode === DateRangeMode.DAY;

        const returnedParameters = Object.keys(timeSeriesResponse.timeseries_properties);
        const parameters: ParameterSettingWithData[] = section.parameters
            .filter((parameter) => isOneDayView || !parameter.dayViewOnly)
            .map((parameter) => ({
                ...parameter,
                unit: timeSeriesResponse.timeseries_properties[parameter.featureName] || parameter.defaultUnit,
                total: {
                    value: timeSeriesResponse.data.summary[parameter.featureName],
                    unit: timeSeriesResponse.summary_properties[parameter.featureName],
                },
                data: timeSeries.map((series) => ({
                    y: series[parameter.featureName],
                    x: new Date(series.timestamp).getTime(),
                })),
                isNotAvailable: !returnedParameters.includes(parameter.featureName),
            }));

        const units = this.unique(parameters.map((p) => p.unit)).map((key) => ({
            key,
            label: this.t.instant(`DATA_ANALYTICS.UNITS.${key}`),
        }));

        const summary =
            section.summaryParameter &&
            <SummaryParameterData>{
                ...section.summaryParameter,
                total: timeSeriesResponse.data.summary[section.summaryParameter.featureName],
                unit: timeSeriesResponse.summary_properties[section.summaryParameter.featureName],
            };

        return {
            ...section,
            dateRange,
            units,
            parameters,
            summary,
            timestamps: timeSeriesResponse.data.timeseries.map((record) => new Date(record.timestamp)),
        };
    }

    private translateSection(section: ParameterSection): ParameterSection {
        return {
            ...section,
            label: this.t.instant(`DATA_ANALYTICS.PARAMETERS.${section.rootSection}.SECTIONS.${section.type}.TITLE`),
            parameters: section.parameters.map((parameter) => ({
                ...parameter,
                label: this.t.instant(
                    `DATA_ANALYTICS.PARAMETERS.${section.rootSection}.SECTIONS.${section.type}.PARAMETERS.${parameter.featureName}`,
                ),
            })),
            summaryParameter: section.summaryParameter && {
                ...section.summaryParameter,
                label: this.t.instant(
                    `DATA_ANALYTICS.PARAMETERS.${section.rootSection}.SECTIONS.${section.type}.PARAMETERS.${section.summaryParameter.featureName}`,
                ),
            },
        };
    }

    private unique(values: string[]) {
        return [...new Set(values)];
    }

    private isNullRecord(record: TimeSeriesRecordFeatures & { timestamp: string }) {
        return Object.entries(record).every(([key, value]) => value === null || key === 'timestamp');
    }
}

export interface ParameterSetting {
    featureName: TimeSeriesProperties;
    label?: string;
    dayViewOnly?: boolean;
    axisColor: string;
    strokeColor?: string;
    defaultUnit?: string;
    axisDashed?: boolean;
    icon: string;
}

export interface SummaryParameterSetting {
    featureName: TimeSeriesProperties;
    label?: string;
}

export interface ParameterSection {
    type: ParameterSectionType;
    label?: string;
    rootSection: ParameterRootSection;
    parameters: ParameterSetting[];
    summaryParameter?: SummaryParameterSetting;
}

export interface ParameterSectionWithData extends ParameterSection {
    units: { key: string; label: string }[];
    dateRange: DateRange;
    parameters: ParameterSettingWithData[];
    timestamps: Date[];
    summary?: SummaryParameterData;
}

export interface SummaryParameterData {
    featureName: TimeSeriesProperties;
    label: string;
    total: number;
    unit: string;
}

export interface ParameterSettingWithData extends ParameterSetting {
    data: { x: number; y: number }[];
    unit: string;
    total: {
        value: number;
        unit: string;
    };
    isNotAvailable: boolean;
}

export enum ParameterSectionType {
    ENERGY_BALANCE = 'ENERGY_BALANCE',
    ENERGY_SOURCES = 'ENERGY_SOURCES',
    ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION',
    ENERGY_PRODUCTION = 'ENERGY_PRODUCTION',
}

export enum ParameterRootSection {
    ENERGY_BALANCE = 'ENERGY_BALANCE',
    CONSUMPTION_VIEW = 'CONSUMPTION_VIEW',
    PRODUCTION_VIEW = 'PRODUCTION_VIEW',
}

export interface DateRange {
    startDate: Date;
    endDate: Date;
    rangeMode: DateRangeMode;
}

export interface WrongDataError {
    error: 'NOT_ENOUGH_DATA';
}
