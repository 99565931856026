import { Component, Input, TrackByFunction } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeMode } from '../../services/DateRangeMode';
import {
    ParameterSectionWithData,
    ParameterSettingWithData,
    SummaryParameterData,
} from '../../services/parameters.service';
import { ChartComponent } from '../chart/chart.component';
import { AssetService } from 'src/app/modules/shared/services/asset.service';
import { DateService } from 'src/app/modules/shared/services/date.service';

@Component({
    selector: 'app-chart-table',
    templateUrl: './chart-table.component.html',
    styleUrls: ['./chart-table.component.scss'],
})
export class ChartTableComponent {
    @Input() data!: ParameterSectionWithData;

    @Input() chart!: ChartComponent;

    constructor(
        public assetService: AssetService,
        private translate: TranslateService,
        private dateService: DateService,
    ) {}

    formatParameter(parameter: ParameterSettingWithData) {
        if (parameter.isNotAvailable) {
            return this.translate.instant('DATA_ANALYTICS.NOT_AVAILABLE');
        }
        if (!parameter.total || parameter?.dayViewOnly) {
            return '---';
        }

        const unit = this.translate.instant(`DATA_ANALYTICS.UNITS.${parameter.total.unit}`);
        return `${parameter.total.value} ${unit}`;
    }

    formatSummaryParameter(parameter: SummaryParameterData) {
        if (!parameter.total) {
            return '---';
        }

        const unit = this.translate.instant(`DATA_ANALYTICS.UNITS.${parameter.unit}`);
        return `${parameter.total} ${unit}`;
    }

    public trackByParametersSections: TrackByFunction<ParameterSettingWithData> = (_index, item) => item.featureName;

    get dateRangeText() {
        const dateRange = this.data.dateRange;
        switch (dateRange.rangeMode) {
            case DateRangeMode.DAY:
                return this.dateService.formatWithLocale(dateRange.startDate, 'dd. MMMM yyyy');
            case DateRangeMode.MONTH:
                return `${this.dateService.formatWithLocale(
                    dateRange.startDate,
                    'dd.',
                )} - ${this.dateService.formatWithLocale(dateRange.endDate, 'dd. MMMM yyyy')}`;
            case DateRangeMode.YEAR:
                return `${this.dateService.formatWithLocale(dateRange.startDate, 'yyyy')}`;
            case DateRangeMode.ALL: {
                const firstTimestamp = this.data.timestamps.at(0);
                const lastTimestamp = this.data.timestamps.at(-1);
                if (firstTimestamp && lastTimestamp) {
                    return `${this.dateService.formatWithLocale(
                        firstTimestamp,
                        'yyyy',
                    )} - ${this.dateService.formatWithLocale(lastTimestamp, 'yyyy')}`;
                }
                return '';
            }
        }
    }
}
