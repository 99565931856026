import { Component, Input } from '@angular/core';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
    @Input() text: string | undefined;
    constructor(public assetService: AssetService) {}
}
