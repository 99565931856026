import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

declare const csrfToken: string;

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            !request.url.includes(environment.energyCockpitConfig.baseUrl) ||
            request.url.includes(environment.energyCockpitConfig.translationsBaseUrl)
        ) {
            return next.handle(request);
        }
        return next.handle(
            request.clone({
                headers: request.headers.set('Authorization', `CSRF ${csrfToken}`),
            }),
        );
    }
}
