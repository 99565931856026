<div class="flex-row justify-content-between">
    <div class="flex-child custom-col">
        <energy-circle-chart
            data-testid="analysis-autarky-chart"
            [value]="autarkyChartValue"
            [colors]="['#2CC84D', '#EDEDED']"
            [status]="status"
        ></energy-circle-chart>
        <energy-dialog-open-button
            data-testid="analysis-autarky-button"
            [series]="autarkyChartItems"
            [status]="status"
            [type]="ConsumptionType.AUTARKY"
        ></energy-dialog-open-button>
    </div>
    <div class="flex-child custom-col">
        <energy-circle-chart
            data-testid="analysis-self-consumption-chart"
            [value]="consumptionChartValue"
            [colors]="['#FBC640', '#EDEDED']"
            [status]="status"
        ></energy-circle-chart>
        <energy-dialog-open-button
            data-testid="analysis-self-consumption-button"
            [series]="consumptionChartItems"
            [status]="status"
            [type]="ConsumptionType.SELF_CONSUMPTION"
        ></energy-dialog-open-button>
    </div>
    <div class="flex-child custom-col-md" *ngIf="environmentStatistics?.co2Emission?.unit !== 'n/a'">
        <vi-environment [environmentStatistics]="environmentStatistics" [status]="status"></vi-environment>
    </div>
</div>
