import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatISO } from 'date-fns';
import { map, Observable, repeat } from 'rxjs';
import { IotFeature } from '../IotFeatures';
import {
    FeatureSubscription,
    InstallationOverviewResponse,
    IotBackendService,
    TimeSeriesResponse,
    TimeSeriesProperties,
    TimeSeriesResolution,
} from './iot-backend.service';
import { LiveUpdateService } from './live-updates.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpIotBackendService implements IotBackendService {
    constructor(
        private http: HttpClient,
        private liveUpdateService: LiveUpdateService,
    ) {}

    getInstallation(installationId: string) {
        return this.http.get<InstallationOverviewResponse>(
            `${environment.energyCockpitConfig.baseUrl}/iot/v1/equipment/installations/${installationId}`,
            {
                params: {
                    includeGateways: true,
                },
            },
        );
    }

    getDeviceFeatures({ gatewayId, deviceId, features, installationId }: FeatureSubscription) {
        return this.http
            .get<{ data: IotFeature[] }>(
                `${environment.energyCockpitConfig.baseUrl}/iot/v1/features/installations/${installationId}/gateways/${gatewayId}/devices/${deviceId}/features`,
                {
                    params: {
                        filter: features.join(','),
                    },
                },
            )
            .pipe(map((response) => response.data));
    }

    subscribeToDeviceFeaturesUpdates(featureSubscriptions: FeatureSubscription[]): Observable<IotFeature[]> {
        return this.liveUpdateService.subscribeToLiveUpdates(featureSubscriptions).pipe(
            map((feature) => [feature]),
            repeat(),
        );
    }

    getTimeSeriesData({
        gatewayId,
        deviceId,
        startDate,
        endDate,
        resolution,
        properties,
    }: {
        gatewayId: string;
        deviceId: string;
        startDate: Date;
        endDate: Date;
        resolution: TimeSeriesResolution;
        properties: TimeSeriesProperties[];
    }) {
        return this.http
            .post<{ data: TimeSeriesResponse }>(
                `${environment.energyCockpitConfig.baseUrl}/iot/v1/analytics-api/dataLake/hems/v0/timeseries`,
                {
                    gateway_id: gatewayId,
                    ...(deviceId ? { device_id: deviceId } : {}),
                    start_datetime: formatISO(startDate),
                    end_datetime: formatISO(endDate),
                    resolution: resolution,
                    properties,
                },
            )
            .pipe(map((response) => response.data));
    }
}
