<div>
    <apx-chart
        [series]="series"
        [dataLabels]="chartOptions.dataLabels"
        [chart]="chartOptions.chart"
        [colors]="colors"
        [fill]="chartOptions.fill"
        [stroke]="stroke"
        [yaxis]="yaxis"
        [xaxis]="xaxis"
        [legend]="chartOptions.legend"
        [grid]="chartOptions.grid"
        [tooltip]="chartOptions.tooltip"
        [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
</div>
