import { Component } from '@angular/core';

@Component({
    selector: 'app-static-content',
    templateUrl: './static-content.component.html',
    styleUrls: ['./static-content.component.scss'],
})
export class StaticContentComponent {
    public code = `
    <script>
    ...
    const myElement = document.getElementById('vi-energy-cockpit');
    
    myElement.addEventListener('statusChange', (event) => {
      console.log('statusChange event fired!', event);
    });
    ...
    </script>`;
}
