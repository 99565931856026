<div class="flex-row flex-fill">
    <div class="flex-child d-flex align-items-center">
        <ng-container *ngIf="status === 'default'; else skeleton">
            <apx-chart
                [colors]="colors!"
                [series]="chartSeries!"
                [chart]="chartOptions.chart!"
                [responsive]="chartOptions.responsive || []"
                [tooltip]="chartOptions.tooltip!"
                [legend]="chartOptions.legend!"
                [states]="chartOptions.states!"
                [plotOptions]="chartOptions.plotOptions!"
                [dataLabels]="chartOptions.dataLabels!"
            ></apx-chart>
        </ng-container>
    </div>
</div>
<div class="flex-row flex-fill align-items-end" *ngIf="textLabel">
    <div class="flex-child d-flex justify-content-center">
        <span class="text-label" [ngClass]="status">
            {{ textLabel }}
        </span>
    </div>
</div>

<ng-template #skeleton>
    <ngx-skeleton-loader [appearance]="'custom-content'">
        <img class="placeholder-img" [src]="assetService.getAssetPath('images/chart_skeleton.svg')" />
    </ngx-skeleton-loader>
</ng-template>
