<div class="headline-section body">
    <div class="d-flex flex-column pt-1">
        <h2 data-testid="parameters-header" class="mb-0">{{ 'DATA_ANALYTICS.PARAMETERS.DIALOG.TITLE' | translate }}</h2>
        <span data-testid="selection-status" class="selection-status" *ngIf="selectedSectionType">{{
            'DATA_ANALYTICS.PARAMETERS.DIALOG.SUBTITLE' | translate: selectionStats
        }}</span>
    </div>
    <button data-testid="parameters-close-button" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="close" class="close-icon"></mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="container">
        <mat-radio-group [(ngModel)]="selectedSectionType" (ngModelChange)="selectedSectionChange($event)">
            <div class="parameters-sections">
                <div class="parameters-section" *ngFor="let rootSection of rootSections">
                    <span class="section-title" data-testid="selection-title">{{
                        'DATA_ANALYTICS.PARAMETERS.' + rootSection.rootSection + '.TITLE' | translate
                    }}</span>
                    <div
                        data-testid="parameters-section-subsection"
                        class="parameter-section-subsection"
                        *ngFor="let section of rootSection.sections"
                    >
                        <mat-radio-button
                            data-testid="parameters-radio-button"
                            color="primary"
                            [hidden]="!showRadioButtons"
                            [value]="section.type"
                            >{{ section.label }}</mat-radio-button
                        >
                        <ul [className]="showRadioButtons ? 'with-radios' : ''">
                            <li *ngFor="let parameter of section.parameters">
                                <mat-checkbox
                                    data-testid="parameters-checkbox"
                                    color="primary"
                                    [disableRipple]="true"
                                    [disabled]="
                                        selectedSectionType !== section.type || !canParameterBeSelected(parameter)
                                    "
                                    [checked]="isSelected(parameter, section)"
                                    (change)="changeParameterSelection(parameter, $event.checked)"
                                >
                                    {{ parameter.label }}
                                    <span class="day-view" *ngIf="parameter.dayViewOnly">{{
                                        'DATA_ANALYTICS.PARAMETERS.DAY_VIEW_ONLY_WARNING' | translate
                                    }}</span>
                                </mat-checkbox>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-radio-group>
    </div>
</div>

<div mat-dialog-actions>
    <button
        data-testid="parameters-apply-button"
        class="apply-button"
        mat-raised-button
        color="primary"
        [disabled]="this.selectedFeatures.length === 0"
        (click)="save()"
    >
        {{ 'DATA_ANALYTICS.PARAMETERS.DIALOG.APPLY' | translate }}
    </button>
</div>
