<div class="table-container body">
    <div class="date-range-header">
        <span data-testid="analysis-table-date-range">{{ dateRangeText }}</span>
    </div>
    <h4 data-testid="analysis-table-header">{{ data.label }}</h4>

    <ng-container *ngIf="!!data.summary">
        <div class="parameter-container">
            <div class="parameter-row">
                <div class="section-label-group">
                    <span data-testid="total-parameters-label" class="label">{{ data.summaryParameter?.label }}</span>
                </div>
                <div data-testid="total-parameters-value" class="parameter-value">
                    {{ formatSummaryParameter(data.summary) }}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let parameter of data.parameters; trackBy: trackByParametersSections">
        <div class="parameter-container">
            <div class="parameter-row">
                <div class="section-label-group">
                    <div
                        class="spacer"
                        [ngClass]="{ 'dashed-border': parameter.axisDashed }"
                        [ngStyle]="
                            parameter.axisDashed
                                ? { background: '#fff', border: '1px dashed ' + parameter.axisColor }
                                : { background: parameter.axisColor }
                        "
                    ></div>
                    <div class="image-container">
                        <svg-icon [src]="assetService.getAssetPath('icons/' + parameter.icon)" />
                    </div>
                    <span data-testid="analysis-table-parameter-label" class="label">{{ parameter.label }}</span>
                </div>
                <div data-testid="analysis-table-parameter-value" class="parameter-value">
                    {{ formatParameter(parameter) }}
                    <span class="day-view-warning" *ngIf="parameter.dayViewOnly">{{
                        'DATA_ANALYTICS.PARAMETERS.DAY_VIEW_ONLY_TABLE_WARNING' | translate
                    }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
