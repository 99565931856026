import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, startWith, switchMap } from 'rxjs';
import { LoadingStatus, Theme } from '../shared/parameterTypes';
import { DataService, EnergyCockpitData } from '../shared/services/data.service';
import { MockedIotBackendService } from './mocked-iot-backend.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-demo-page',
    templateUrl: './demo-page.component.html',
    styleUrls: ['./demo-page.component.scss'],
})
export class DemoPageComponent implements OnInit {
    private APP_ID = 'user-portal';
    private http: HttpClient;

    constructor(
        private route: ActivatedRoute,
        public httpBackend: HttpBackend,
        public mockedBackend: MockedIotBackendService,
        private iotService: DataService,
    ) {
        this.http = new HttpClient(httpBackend);
    }

    public ready = false;

    public lang: string | undefined;

    public theme: Theme | undefined;
    public hideManual = true;
    public hideSimulation = false;

    public detail: 'minimal' | 'full' = 'full';
    public installationIdFormControl = new FormControl();
    public mock$ = new BehaviorSubject<boolean>(false);

    public token = new BehaviorSubject<string | undefined>(undefined);

    ngOnInit() {
        this.route.queryParamMap.subscribe((params) => {
            this.installationIdFormControl.setValue(params.get('installationid') || undefined);
            this.mock$.next(params.get('mock') === 'true');
            this.lang = params.get('lang') || undefined;
            this.theme = <Theme>params.get('theme') || 'light';
            this.detail = <'minimal' | 'full'>params.get('detail') || 'full';
            this.hideManual = params.get('hideManual') === 'true';
            this.hideSimulation = params.get('hideSimulation') === 'true';
        });

        this.token.subscribe((token) => {
            if (token) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (<any>window).csrfToken = <string>token;
                this.ready = true;
            }
        });

        const hash = window.location.hash;
        if (hash?.startsWith('#token=')) {
            const tokenValue = <string>new HttpParams({ fromString: hash }).get('#token');
            window.location.hash = '';
            this.token.next(tokenValue);
        } else {
            this.http
                .post<{ token: string }>(
                    `${environment.energyCockpitConfig.baseUrl}/auth/v1/saml/csrf`,
                    { appId: this.APP_ID },
                    {
                        withCredentials: true,
                    },
                )
                .subscribe({
                    next: (result) => this.token.next(result.token),
                    error: () => (this.ready = true),
                });
        }
    }

    data$: Observable<{
        data?: EnergyCockpitData;
        status: LoadingStatus;
    }> = combineLatest([
        this.installationIdFormControl.valueChanges.pipe(startWith(this.installationIdFormControl.value)),
        this.mock$.pipe(startWith(false)),
    ]).pipe(
        switchMap(([installationId, mock]) =>
            this.iotService.loadData(installationId || '', mock ? this.mockedBackend : undefined).pipe(
                map((data) => ({ data, status: <const>'default' })),
                startWith({ status: <const>'pending' }),
                catchError(() => of({ status: <const>'error' })),
            ),
        ),
    );

    login() {
        window.location.href = `${environment.energyCockpitConfig.baseUrl}/saml/sso/request?appId=${this.APP_ID}`;
    }
}
