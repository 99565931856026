<div class="container">
    <div class="content-container">
        <div class="row">
            <h3 class="title">{{ 'DATA_ANALYTICS.BASE_COMPONENT.TITLE' | translate }}</h3>
        </div>

        <vi-data-analytics-control
            [loading]="(loading$ | async) || false"
            (settingsChanged)="settings$.next($event)"
        ></vi-data-analytics-control>

        <div class="chart">
            <ng-container *ngIf="timeSeriesData$ | async as timeSeriesData; else chartLoader">
                <ng-container *ngIf="!hasError(timeSeriesData); else noData">
                    <app-chart
                        data-testid="chart-component"
                        [data]="timeSeriesData"
                        #chartRef
                        class="chart-item"
                    ></app-chart>
                    <app-chart-table [data]="timeSeriesData" [chart]="chartRef" class="chart-item"></app-chart-table>
                    <pie-charts-statistics
                        *ngIf="timeSeriesResponse"
                        [timeSeriesResponse]="timeSeriesResponse"
                        class="chart-item"
                    ></pie-charts-statistics>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #chartLoader>
    <ngx-skeleton-loader [appearance]="'custom-content'">
        <svg-icon [src]="assetService.getAssetPath('images/data_chart-group.svg')" />
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
        [animation]="'progress-dark'"
        [appearance]="'line'"
        [count]="2"
        [theme]="{ width: '100%', height: '56px', margin: '0 4px' }"
    >
    </ngx-skeleton-loader>
</ng-template>

<ng-template #noData>
    <app-no-data></app-no-data>
</ng-template>
