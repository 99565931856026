import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear, sub } from 'date-fns';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounce,
    filter,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap,
    timer,
} from 'rxjs';
import { AssetService } from '../shared/services/asset.service';
import { HttpIotBackendService } from '../shared/services/iot-backend/http-iot-backend.service';
import {
    IotBackendService,
    TimeSeriesResolution,
    TimeSeriesResponse,
} from '../shared/services/iot-backend/iot-backend.service';
import { AnalyticsSettings } from './components/controls/controls.component';
import { ChartDataService } from './services/chart-data.service';
import { DateRangeMode } from './services/DateRangeMode';
import {
    ParameterSectionType,
    ParameterSectionWithData,
    ParametersService,
    WrongDataError,
} from './services/parameters.service';

@Component({
    selector: 'vi-data-analytics',
    templateUrl: './data-analytics.component.html',
    styleUrls: ['./data-analytics.component.scss'],
})
export class DataAnalyticsComponent {
    @Input() set mock(mock: IotBackendService | undefined) {
        this.mock$.next(mock);
    }

    @Input() set batteryDeviceId(batteryDeviceId: string | undefined) {
        this.batteryDeviceId$.next(batteryDeviceId);
    }

    @Input() set gatewayId(gatewayId: string | undefined) {
        this.gatewayId$.next(gatewayId);
    }

    @Input() set lang(lang: string | undefined) {
        if (lang) {
            this.translateService.use(lang);
        }
    }

    settings$ = new Subject<AnalyticsSettings>();
    batteryDeviceId$ = new BehaviorSubject<string | undefined>(undefined);
    gatewayId$ = new BehaviorSubject<string | undefined>(undefined);
    timeSeriesResponse: TimeSeriesResponse | undefined;
    mock$ = new BehaviorSubject<IotBackendService | undefined>(undefined);

    constructor(
        public assetService: AssetService,
        private parametersService: ParametersService,
        private characterDataService: ChartDataService,
        public iotBackendService: HttpIotBackendService,
        private translateService: TranslateService,
    ) {
        this.assetService.registerIcons([
            'expand_more',
            'date_range',
            'chevron_left',
            'chevron_right',
            'close',
            'info',
        ]);

        translateService.setDefaultLang(this.lang || 'de');
    }

    timeSeriesData$ = combineLatest([
        // eslint-disable-next-line @everest/no-useless-undefined
        this.mock$.pipe(startWith(undefined)),
        this.batteryDeviceId$,
        this.gatewayId$.pipe(filter(Boolean)),
        this.settings$,
    ]).pipe(
        debounce(() => timer(100)),
        switchMap(
            ([
                iotBackend,
                batteryDeviceId,
                gatewayId,
                { rangeMode, selectedDate, selectedFeatures, selectedSection },
            ]) => {
                const section = this.parametersService.getSection(selectedSection);
                const dateRange = this.generateRange(selectedDate, rangeMode || DateRangeMode.MONTH);

                const featuresToFetch = [...selectedFeatures];
                if (this.shouldDisplayPieCharts(selectedSection, rangeMode)) {
                    featuresToFetch.push(...Object.values(this.characterDataService.CHART_FEATURES));
                }

                if (section.summaryParameter) {
                    featuresToFetch.push(section.summaryParameter.featureName);
                }

                if (!iotBackend) {
                    iotBackend = this.iotBackendService;
                }

                return combineLatest({
                    dateRange: of(dateRange),
                    selectedSection: of(selectedSection),
                    timeSeriesResponse: iotBackend
                        .getTimeSeriesData({
                            startDate: dateRange.startDate,
                            endDate: dateRange.endDate,
                            gatewayId,
                            deviceId: batteryDeviceId,
                            properties: featuresToFetch,
                            resolution: dateRange.resolution,
                        })
                        .pipe(
                            startWith(null),
                            catchError((e) => of({ error: e })),
                        ),
                });
            },
        ),
        tap(({ timeSeriesResponse, selectedSection, dateRange: { rangeMode } }) => {
            if (
                timeSeriesResponse &&
                !this.isTimeSeriesErrorResponse(timeSeriesResponse) &&
                this.shouldDisplayPieCharts(selectedSection, rangeMode)
            ) {
                this.timeSeriesResponse = <TimeSeriesResponse>timeSeriesResponse;
                return;
            }
            this.timeSeriesResponse = undefined;
        }),
        map(({ timeSeriesResponse, dateRange, selectedSection }) => {
            if (timeSeriesResponse && this.isTimeSeriesErrorResponse(timeSeriesResponse)) {
                return <WrongDataError>{ error: 'NOT_ENOUGH_DATA' };
            }

            if (timeSeriesResponse && selectedSection) {
                return this.parametersService.mergeSectionWithTimeSeriesData(
                    selectedSection,
                    timeSeriesResponse,
                    dateRange,
                );
            }
            return null;
        }),
        shareReplay(1),
    );

    loading$: Observable<boolean> = this.timeSeriesData$.pipe(
        map((value) => !value),
        startWith(true),
    );

    hasError(data: ParameterSectionWithData | WrongDataError): data is WrongDataError {
        return (<WrongDataError>data).error !== undefined;
    }

    shouldDisplayPieCharts(selectedSection?: ParameterSectionType, rangeMode?: DateRangeMode | null) {
        return selectedSection === ParameterSectionType.ENERGY_BALANCE && rangeMode !== DateRangeMode.DAY;
    }

    private generateRange(
        selectedDate: Date,
        rangeMode: DateRangeMode,
    ): { startDate: Date; endDate: Date; resolution: TimeSeriesResolution; rangeMode: DateRangeMode } {
        switch (rangeMode) {
            case DateRangeMode.DAY:
                return {
                    rangeMode,
                    startDate: startOfDay(selectedDate),
                    endDate: endOfDay(selectedDate),
                    resolution: '15m',
                };
            case DateRangeMode.MONTH:
                return {
                    rangeMode,
                    startDate: startOfMonth(selectedDate),
                    endDate: endOfMonth(selectedDate),
                    resolution: '1d',
                };
            case DateRangeMode.YEAR:
                return {
                    rangeMode,
                    startDate: startOfYear(selectedDate),
                    endDate: endOfYear(selectedDate),
                    resolution: '1M',
                };
            case DateRangeMode.ALL:
                return {
                    rangeMode,
                    startDate: startOfYear(sub(selectedDate, { years: 10 })),
                    endDate: new Date(),
                    resolution: '1Y',
                };
            default:
                throw Error('Wrong range');
        }
    }

    private isTimeSeriesErrorResponse(
        data: TimeSeriesResponse | null | { error: unknown },
    ): data is { error: unknown } {
        return !!(<{ error: unknown }>data).error;
    }
}
