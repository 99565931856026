import { Injectable } from '@angular/core';
import { add, differenceInDays, differenceInMonths, formatRFC3339, isAfter, isBefore, startOfDay } from 'date-fns';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import {
    IotBackendService,
    TimeSeriesInput,
    TimeSeriesProperties,
    TimeSeriesRecordFeatures,
    TimeSeriesRecordFeaturesUnits,
    TimeSeriesResolution,
} from '../shared/services/iot-backend/iot-backend.service';
import {
    EmsKpiFeature,
    EmsPowerBalanceDeliveredType,
    EmsPowerBalanceFeature,
    EmsPowerBalanceReceivedType,
    EmsStateOfChargeFeature,
    IotFeature,
} from '../shared/services/IotFeatures';

@Injectable({
    providedIn: 'root',
})
export class MockedIotBackendService implements IotBackendService {
    events$ = new BehaviorSubject<IotFeature[]>([]);
    public maximumAnalyticsValue = 40;
    public hideFeature = '';
    getInstallation() {
        return of({
            data: {
                id: 123456,
                description: '',
                gateways: [
                    {
                        serial: '7736170000000000',
                        version: '508.2243.4.0',
                        targetRealm: 'DC',
                        devices: [
                            {
                                gatewaySerial: '7736170000000000',
                                id: '7372956200000000',
                                boilerSerial: '7372956200000000',
                                boilerSerialEditor: 'DeviceCommunication',
                                bmuSerial: null,
                                bmuSerialEditor: null,
                                createdAt: '2022-04-26T12:14:40.357Z',
                                editedAt: '2023-03-29T13:19:10.055Z',
                                modelId: 'E3_VitoCharge_0122',
                                status: 'Online',
                                deviceType: 'electricityStorage',
                                roles: [
                                    'type:E3',
                                    'type:cascadeV2Lag',
                                    'type:ess',
                                    'type:photovoltaic',
                                    'type:photovoltaic:Internal',
                                    'type:product;Vitocharge',
                                ],
                            },
                            {
                                gatewaySerial: '7736170000000000',
                                id: 'HEMS',
                                boilerSerial: null,
                                boilerSerialEditor: null,
                                bmuSerial: null,
                                bmuSerialEditor: null,
                                createdAt: '2022-01-12T11:41:59.483Z',
                                editedAt: '2023-03-29T12:53:59.699Z',
                                modelId: 'E3_HEMS',
                                status: 'Online',
                                deviceType: 'hems',
                                roles: ['type:E3', 'type:virtual', 'type:virtual;hems'],
                            },
                        ],
                        gatewayType: 'SA180Lan',
                        installationId: 123456,
                        registeredAt: '2021-03-01T08:00:00.514Z',
                        description: null,
                        otaOngoing: false,
                    },
                ],
                registeredAt: '2021-03-01T08:00:00.514Z',
                updatedAt: '2022-04-27T11:43:03.792Z',
                aggregatedStatus: 'Maintenance',
                installationType: 'Residential',
            },
        }).pipe(delay(100));
    }

    getDeviceFeatures({ features }: { gatewayId: string; deviceId: string; features: string[] }) {
        return of(
            features.includes('ess.stateOfCharge')
                ? [this.generateStateOfCharge(85)]
                : [
                      this.generateEmsKpi(100, 55),
                      this.generateEmsPowerBalance({
                          delivered: {
                              Accumulator: 100,
                              Grid: 2000,
                              Photovoltaic: 3000,
                          },
                          received: {
                              Accumulator: 0,
                              Grid: 1000,
                              Heatpump: 3000,
                              Household: 1200,
                              VehicleChargingStation: 0,
                          },
                      }),
                  ],
        ).pipe(delay(1500));
    }

    subscribeToDeviceFeaturesUpdates(): Observable<IotFeature[]> {
        return this.events$.asObservable();
    }

    getTimeSeriesData({ gatewayId, deviceId, resolution, properties: rawProps, startDate, endDate }: TimeSeriesInput) {
        const properties = rawProps.filter((prop) => prop !== this.hideFeature);
        const propertyUnits = Object.fromEntries(
            properties.map((property) => [property, this.getUnit(property, false)]),
        );
        const summaryUnits = Object.fromEntries(properties.map((property) => [property, this.getUnit(property, true)]));
        const months = differenceInMonths(startDate, endDate);
        const days = differenceInDays(startDate, endDate) || 1;
        const timeFrameUnit = months > 0 ? 'm' : 'd';
        const date2018 = startOfDay(new Date(2018, 0, 0)); // for dates form 2017 to 2018 we simulate null values

        return of({
            gateway_id: gatewayId,
            device_id: deviceId || '0',
            resolution,
            timeframe: `${timeFrameUnit == 'd' ? days : months}${timeFrameUnit}`,
            summary_properties: <TimeSeriesRecordFeaturesUnits>propertyUnits,
            timeseries_properties: <TimeSeriesRecordFeaturesUnits>summaryUnits,
            data: {
                summary: <TimeSeriesRecordFeatures>this.generateSeries(<TimeSeriesProperties[]>properties),
                timeseries: <(TimeSeriesRecordFeatures & { timestamp: string })[]>this.generateDateRange(
                    startDate,
                    endDate,
                    resolution,
                ).map((date) => ({
                    ...this.generateSeries(
                        <TimeSeriesProperties[]>properties.filter((prop) => prop !== this.hideFeature),
                        isBefore(date, date2018),
                    ),
                    timestamp: formatRFC3339(date),
                })),
            },
        }).pipe(delay(1500));
    }

    private getUnit(property: TimeSeriesProperties, summary?: boolean) {
        if (property === 'ems.carbon.car.distance') {
            return 'km';
        }
        if (property === 'ems.carbon.trees') {
            return 'pc.';
        }

        if (property.startsWith('ems.carbon')) {
            return 'kg';
        }

        if (['ems.kpi.self-consumption', 'ess.stateOfCharge/value'].includes(property)) {
            return 'percentage';
        }

        return summary ? 'kWh' : 'kW';
    }
    private generateSeries(properties: TimeSeriesProperties[], nullSeries = false) {
        return Object.fromEntries(
            properties.map((property) => {
                const value = nullSeries
                    ? null
                    : this.randRange(0, this.getUnit(property) === 'percentage' ? 100 : this.maximumAnalyticsValue);
                return [property, value];
            }),
        );
    }

    private generateDateRange(startDate: Date, endDate: Date, resolution: TimeSeriesResolution) {
        const currentDate = new Date();
        const date2017 = startOfDay(new Date(2017, 0, 0));
        let date = startDate;
        const dates: Date[] = [];

        while (isBefore(date, endDate)) {
            if (isAfter(date, date2017) && isBefore(date, currentDate)) {
                dates.push(date);
            }
            date = this.addDateBasedOnDuration(date, resolution);
        }
        return dates;
    }

    private addDateBasedOnDuration(date: Date, humanDuration: TimeSeriesResolution) {
        const durationPattern = /(\d+)([dhmwy])/i;
        const matches = humanDuration.match(durationPattern);

        if (!matches) {
            throw new Error('Invalid duration format.');
        }

        const [, value, unit] = matches;

        const durationUnitMappings = {
            Y: 'years',
            M: 'months',
            W: 'weeks',
            d: 'days',
            h: 'hours',
            m: 'minutes',
            s: 'seconds',
        };
        const durationUnit = durationUnitMappings[<keyof typeof durationUnitMappings>unit];
        return add(date, { [durationUnit]: Number.parseInt(value) });
    }

    private randRange(min: number, max: number) {
        return Math.floor((Math.random() * (max - min) + min) * 100) / 100;
    }
    generateEmsKpi(autarkyValue: number, selfConsumptionValue: number): EmsKpiFeature {
        return {
            properties: {
                calculationStatus: { type: 'string', value: 'correct' },
                autarky: { type: 'number', value: autarkyValue, unit: 'percent' },
                selfConsumption: { type: 'number', value: selfConsumptionValue, unit: 'percent' },
            },
            commands: {},
            apiVersion: 1,
            uri: '/iot/v1/equipment/gateways/7736170000000000/devices/HEMS/features/ems.kpi',
            gatewayId: '7736170000000000',
            feature: <const>'ems.kpi',
            timestamp: '2023-05-02T15:18:29.940Z',
            isEnabled: true,
            isReady: true,
            deviceId: 'HEMS',
        };
    }

    generateEmsPowerBalance(dataObject: {
        received: { [key in EmsPowerBalanceReceivedType]?: number };
        delivered: { [key in EmsPowerBalanceDeliveredType]?: number };
    }): EmsPowerBalanceFeature {
        return {
            properties: {
                calculationStatus: { type: 'string', value: 'correct' },
                received: {
                    type: 'array',
                    value: Object.entries(dataObject.received).map(([type, value]) => ({
                        type: <EmsPowerBalanceReceivedType>type,
                        value: +value,
                        unit: 'watt',
                        components:
                            type === 'Heatpump'
                                ? [
                                      {
                                          value: Math.floor(+value * 0.8),
                                          unit: 'watt',
                                          type: 'ElectricalHeater',
                                      },
                                      {
                                          value: Math.floor(+value * 0.2),
                                          unit: 'watt',
                                          type: 'System',
                                      },
                                  ]
                                : undefined,
                    })),
                },
                delivered: {
                    type: 'array',
                    value: Object.entries(dataObject.delivered).map(([type, value]) => ({
                        type: <EmsPowerBalanceDeliveredType>type,
                        value: +value,
                        unit: 'watt',
                    })),
                },
                totalConsumption: { type: 'number', value: 1509, unit: '' },
                totalProduction: { type: 'number', value: 8420, unit: '' },
            },
            commands: {},
            apiVersion: 1,
            uri: '/iot/v1/equipment/gateways/7736170000000000/devices/HEMS/features/ems.power.balance',
            gatewayId: '7736170000000000',
            feature: <const>'ems.power.balance',
            timestamp: '2023-05-02T15:14:46.853Z',
            isEnabled: true,
            isReady: true,
            deviceId: 'HEMS',
        };
    }

    generateStateOfCharge(batteryValue: number): EmsStateOfChargeFeature {
        return {
            properties: { value: { type: 'number', value: batteryValue, unit: 'percent' } },
            commands: {},
            apiVersion: 1,
            uri: '/iot/v1/equipment/gateways/7736170000000000/devices/7372956200000000/features/ess.stateOfCharge',
            gatewayId: '7736170000000000',
            feature: <const>'ess.stateOfCharge',
            timestamp: '2023-05-02T15:18:16.981Z',
            isEnabled: true,
            isReady: true,
            deviceId: '7372956200000000',
        };
    }
}
