import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetService } from '../../../shared/services/asset.service';
import { EnvironmentStatistics } from '../../services/chart-data.service';
import { EnvironmentDialogComponent } from '../environment-dialog/environment-dialog.component';

@Component({
    selector: 'vi-environment',
    templateUrl: './environment.component.html',
    styleUrls: ['./environment.component.scss'],
})
export class EnvironmentComponent {
    @Input() environmentStatistics: EnvironmentStatistics | null = null;

    @Input() status: 'default' | 'pending' | 'error' = 'pending';

    constructor(
        private dialog: MatDialog,
        public assetService: AssetService,
    ) {}

    openDialog() {
        if (this.environmentStatistics) {
            this.dialog.open(EnvironmentDialogComponent, {
                data: this.environmentStatistics,
                autoFocus: false,
                maxWidth: '790px',
            });
        }
    }
}
