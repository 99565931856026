import { Component, Input } from '@angular/core';
import { ConsumptionType } from '../../../data-analytics/services/chart-data.service';
import { EnergyTableItemCategory, ValueItemWithUnit } from '../../services/data.service';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

@Component({
    selector: 'app-energy-item',
    templateUrl: './energy-item.component.html',
    styleUrls: ['./energy-item.component.scss'],
})
export class EnergyItemComponent {
    @Input() type!: EnergyTableItemCategory | string;

    @Input() batteryCharge: number | null = null;

    @Input() item!: EnergyItemRepresentation;

    constructor(public assetService: AssetService) {}

    getClassNames(): string[] {
        return [this.item.type.toLowerCase(), this.type.toString()];
    }
}

interface EnergyItemRepresentation extends ValueItemWithUnit {
    icon: string;
    type: string;
    category?: EnergyTableItemCategory;
    consumptionType?: ConsumptionType;
    additionalInfo: string[];
}
