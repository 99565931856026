<div class="table-container flex-container" [ngClass]="type">
    <h4 [attr.data-testid]="'energy-cockpit-table-header-' + type">
        {{ 'ENERGY_COCKPIT.ENERGY_TABLE.TITLE.' + type | translate }}
    </h4>
    <ng-container *ngIf="status === 'default'; else skeleton">
        <div class="flex-container" *ngFor="let item of tableItems; trackBy: trackByEnergyItem">
            <app-energy-item [item]="item" [type]="type" [batteryCharge]="batteryCharge"></app-energy-item>
        </div>
    </ng-container>
</div>

<ng-template #skeleton>
    <ngx-skeleton-loader
        [animation]="'progress-dark'"
        [appearance]="'line'"
        [count]="2"
        [theme]="{ width: '100%', height: '56px', margin: '0 4px' }"
    >
    </ngx-skeleton-loader>
</ng-template>
