import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { add as dateAdd, endOfDay, isAfter, isBefore, startOfYear } from 'date-fns';
import { DateRangeMode } from '../../services/DateRangeMode';
import { DateService } from 'src/app/modules/shared/services/date.service';

@Component({
    selector: 'vi-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    @Input() rangeMode!: DateRangeMode;
    @Input() disabled = true;

    @Output() selectedDate = new EventEmitter<Date>();
    date = new FormControl(new Date());

    minDate: Date;
    maxDate: Date;

    constructor(public dateService: DateService) {
        this.minDate = startOfYear(new Date(2015, 0, 0));
        this.maxDate = endOfDay(new Date());
    }

    ngOnInit() {
        this.date.valueChanges.subscribe((newDate) => {
            if (newDate) {
                this.selectedDate.next(newDate);
            }
        });
    }

    setPrevious() {
        this.jumpDate('prev');
    }
    setNext() {
        this.jumpDate('next');
    }

    canJumpPrevious() {
        return this.canJumpDate('prev');
    }

    canJumpNext() {
        return this.canJumpDate('next');
    }

    jumpDate(direction: 'next' | 'prev') {
        if (!this.date.value) {
            return;
        }

        this.date.setValue(this.getNextDate(this.date.value, direction));
    }

    canJumpDate(direction: 'next' | 'prev') {
        if (!this.date.value) {
            return false;
        }

        const newDate = this.getNextDate(this.date.value, direction);
        return isAfter(endOfDay(newDate), this.minDate) && isBefore(newDate, this.maxDate);
    }

    onMonthSelected(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
        if (this.rangeMode === DateRangeMode.MONTH) {
            this.date.setValue(normalizedMonthAndYear);
            datepicker.close();
        }
    }

    onYearSelected(normalizedYear: Date, datepicker: MatDatepicker<Date>) {
        if (this.rangeMode === DateRangeMode.YEAR) {
            this.date.setValue(normalizedYear);
            datepicker.close();
        }
    }
    get startView() {
        switch (this.rangeMode) {
            case DateRangeMode.DAY:
                return 'month';
            case DateRangeMode.MONTH:
                return 'year';
            case DateRangeMode.YEAR:
            default:
                return 'multi-year';
        }
    }

    get dateFormat() {
        switch (this.rangeMode) {
            case DateRangeMode.YEAR:
                return 'y';
            case DateRangeMode.MONTH:
                return 'MMMM y';
            case DateRangeMode.DAY:
                return 'dd. MMMM y';
            default:
                return '';
        }
    }

    private getNextDate(date: Date, direction: 'next' | 'prev') {
        const valueToAdd = 1 * (direction === 'next' ? 1 : -1);
        const duration =
            this.rangeMode === DateRangeMode.DAY
                ? { days: valueToAdd }
                : this.rangeMode === DateRangeMode.MONTH
                  ? { months: valueToAdd }
                  : { years: valueToAdd };

        return dateAdd(date, duration);
    }
}
