import { Environment } from './environment.model';

export const environment: Environment = {
    production: false,
    assetBasePath: 'https://energy-cockpit-staging.viessmann.com/assets/',
    energyCockpitConfig: {
        baseUrl: 'https://api-staging.viessmann.com',
        translationsBaseUrl: 'https://api-staging.viessmann.com/translations/v2/',
    },
};
