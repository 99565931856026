<div class="flex-row body">
    <div class="flex-child">
        <div class="spacer" [ngClass]="getClassNames()"></div>
    </div>
    <div class="flex-child icon-container">
        <svg-icon [src]="assetService.getAssetPath(item.icon)" />
    </div>
    <div class="flex-child flex-grow-1">
        <div class="flex-column">
            <div class="flex-child item-label" data-testid="energy-item-label" *ngIf="item.consumptionType">
                {{ 'ENERGY_COCKPIT.ENERGY_DIALOG.LABELS.' + item.consumptionType + '.' + item.type | translate }}
            </div>
            <div
                [attr.data-testid]="item.type + '-' + item.category + '-label'"
                class="flex-child item-label"
                *ngIf="item.category"
            >
                {{ 'ENERGY_COCKPIT.ENERGY_TABLE.LABELS.' + item.category + '.' + item.type | translate }}
            </div>
            <div
                [attr.data-testid]="item.type + '-' + item.category + '-soc-label'"
                class="flex-child additional-info"
                *ngIf="item.additionalInfo.includes('isBattery') && batteryCharge"
            >
                {{ 'ENERGY_COCKPIT.ENERGY_TABLE.LABELS.STATE_OF_CHARGE' | translate }}
            </div>
            <div class="flex-child additional-info" *ngIf="item.additionalInfo.includes('hasElectricalHeater')">
                {{ 'ENERGY_COCKPIT.ENERGY_TABLE.LABELS.ELECTRICAL_HEATER_ACTIVE' | translate }}
            </div>
        </div>
    </div>
    <div class="flex-child">
        <div class="flex-column">
            <div [attr.data-testid]="item.type + '-' + item.category + '-value'" class="flex-child">
                {{ item | formatPowerUnits }}
            </div>
            <div
                [attr.data-testid]="item.type + '-' + item.category + '-soc-value'"
                class="flex-child additional-info flex-end"
                *ngIf="item.additionalInfo.includes('isBattery') && batteryCharge"
            >
                {{ batteryCharge }}%
            </div>
        </div>
    </div>
</div>
