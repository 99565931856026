import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay, startOfMonth, format } from 'date-fns';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexStroke,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ApexPlotOptions,
} from 'ng-apexcharts';
import { DateRangeMode } from '../../services/DateRangeMode';
import { DateRange, ParameterSectionWithData, ParameterSettingWithData } from '../../services/parameters.service';
import { TimeSeriesUnits } from 'src/app/modules/shared/services/iot-backend/iot-backend.service';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
    constructor(private translate: TranslateService) {}
    @Input() set data(data: ParameterSectionWithData) {
        const unitSeries = data.units.map((unit) => ({
            unit,
            series: data.parameters
                .filter((s) => s.unit === unit.key)
                .map((parameter) => ({ ...parameter, name: parameter.label })),
        }));
        const parameters = unitSeries.flatMap((unit) => unit.series);

        const hasOneOrLessRowOfData = data.parameters.filter((v) => v.data.length < 2).length > 0;

        this.series = parameters;

        this.yaxis = this.generateYAxis(data, unitSeries);
        this.xaxis = this.generateXAxis(data);
        const isOneDayView = data.dateRange.rangeMode === DateRangeMode.DAY;
        this.colors = parameters.map((parameter) => parameter.axisColor);
        this.stroke = {
            curve: 'smooth',
            colors: parameters.map((parameter) => parameter.strokeColor || parameter.axisColor),
            width: isOneDayView ? parameters.map((parameter) => (parameter.axisDashed ? 1 : 2)) : 0,
            dashArray: parameters.map((parameter) => (parameter.axisDashed ? 2 : 0)),
        };

        this.chartOptions = this.generateChartOption({
            mode: isOneDayView ? 'area' : 'bar',
            rangeMode: data.dateRange.rangeMode,
            hasOneOrLessRowOfData,
            gradientColors: parameters.map((parameter) => (parameter.axisDashed ? '#ffffff' : parameter.axisColor)),
        });
    }
    public series: ApexAxisChartSeries = [];

    public yaxis: ApexYAxis[] = [];
    public xaxis: ApexXAxis = {};
    public colors: string[] = [];
    public stroke: ApexStroke = {};

    private generateChartOption({
        mode,
        rangeMode,
        hasOneOrLessRowOfData,
        gradientColors,
    }: {
        mode: 'area' | 'bar';
        rangeMode?: DateRangeMode;
        hasOneOrLessRowOfData?: boolean;
        gradientColors?: string[];
    }): ChartOptions {
        const defaultOptions = {
            dataLabels: {
                enabled: false,
            },
            legend: { show: false },
            chart: <ApexChart>{
                height: '400px',
                width: '100%',
                toolbar: {
                    show: false,
                },
                zoom: { enabled: false },
            },
            tooltip: {
                x: {
                    show: rangeMode !== DateRangeMode.DAY,
                    format: rangeMode ? this.dateFormats[rangeMode] : 'dd MM',
                },
                y: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    formatter: (value: number, opts: any) => {
                        if (value === undefined) {
                            return this.translate.instant('DATA_ANALYTICS.NOT_AVAILABLE');
                        }
                        const unit = opts.w.config.series[opts.seriesIndex].unit;
                        const unitString = ` ${unit}`;
                        return `${value}${unit === 'percentage' ? '%' : unitString}`;
                    },
                },
            },
            grid: {
                padding: {
                    left: 5,
                    right: 5,
                    bottom: 10,
                },
            },
            plotOptions: hasOneOrLessRowOfData ? { bar: { columnWidth: 20 } } : {},
        };
        if (mode === 'area') {
            return {
                ...defaultOptions,
                chart: {
                    ...defaultOptions.chart,
                    type: 'area',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        gradientToColors: gradientColors,
                        inverseColors: true,
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0.0,
                    },
                },
            };
        }

        return {
            ...defaultOptions,
            chart: {
                ...defaultOptions.chart,
                type: 'bar',
            },
            fill: {},
        };
    }
    public chartOptions: ChartOptions = this.generateChartOption({
        mode: 'area',
    });

    private labelStyle = {
        fontFamily: 'Inter',
        fontSize: '10px',
        marginRight: '3px',
        fontWeight: '600',
        colors: '#B3B3B3',
        color: '#B3B3B3',
    };

    private dateFormats = <const>{
        [DateRangeMode.ALL]: 'yyyy',
        [DateRangeMode.YEAR]: 'MM',
        [DateRangeMode.MONTH]: 'dd.MM',
        [DateRangeMode.DAY]: 'HH:mm',
    };

    private generateMinMaxRanges(range: DateRange, timestamps: Date[]) {
        const min =
            range.rangeMode === DateRangeMode.ALL && timestamps?.length > 0
                ? timestamps[0].getTime()
                : range.startDate.getTime();
        const max = this.pushBackRangeMaxValue(range).getTime();
        return { min, max };
    }

    private pushBackRangeMaxValue(range: DateRange) {
        if (range.rangeMode === DateRangeMode.MONTH) {
            return startOfDay(range.endDate);
        }
        if (range.rangeMode === DateRangeMode.YEAR) {
            return startOfMonth(range.endDate);
        }
        return range.endDate;
    }

    private getyAxisRangeValues(sourceMaxValue: number) {
        const jump = this.getAxisJump(sourceMaxValue);
        const maxValue = this.ceilTo(sourceMaxValue, jump);
        const decimalsInFloat = jump < 1 ? 2 : 0;
        const tickAmount = maxValue / jump;
        return { tickAmount, maxValue, jump, decimalsInFloat };
    }

    private getAxisJump(maxValue: number): number {
        if (maxValue < 3) {
            return 0.25;
        } else if (maxValue <= 5) {
            return 0.5;
        } else if (maxValue <= 10) {
            return 1;
        } else if (maxValue <= 80) {
            return 10;
        } else if (maxValue <= 300) {
            return 50;
        }
        return 100;
    }

    private ceilTo(value: number, precision: number) {
        return Math.ceil(value / precision) * precision;
    }

    private generateYAxis(
        data: ParameterSectionWithData,
        unitSeries: {
            unit: { key: string; label: string };
            series: (ParameterSettingWithData & { name: string | undefined })[];
        }[],
    ): ApexYAxis[] {
        const isFullMonth = data.timestamps.length >= 30;

        const largestY = data.parameters
            .filter((parameter) => parameter.unit !== TimeSeriesUnits.PERCENTAGE)
            .reduce((maxY, currentObject) => {
                const maxYInCurrent = Math.max(...currentObject.data.map((item) => item.y || 0));
                return Math.max(maxY, maxYInCurrent);
            }, 0);

        return unitSeries.flatMap((unit, index) => {
            const [unitFirstSeries, ...seriesSharingUnit] = unit.series;
            const rightLabelLocation = index === 1;
            const { maxValue, tickAmount, decimalsInFloat } = this.getyAxisRangeValues(largestY);

            return <ApexYAxis[]>[
                {
                    seriesName: unitFirstSeries.name,
                    opposite: rightLabelLocation,
                    ...(unit.unit.key === TimeSeriesUnits.PERCENTAGE
                        ? {
                              min: 0,
                              max: 100,
                              decimalsInFloat: 0,
                          }
                        : { max: maxValue, forceNiceScale: false, decimalsInFloat, tickAmount }),

                    title: {
                        text: unit.unit.label,
                        rotate: 0,
                        offsetY: isFullMonth ? -170 : -180,
                        offsetX: rightLabelLocation ? -25 : 20,
                        style: this.labelStyle,
                    },
                    labels: {
                        offsetX: rightLabelLocation ? -35 : -40,
                        style: this.labelStyle,
                    },
                },
                ...seriesSharingUnit.map(() => ({
                    seriesName: unitFirstSeries.name,
                    show: false,
                })),
            ];
        });
    }

    private generateXAxis(data: ParameterSectionWithData): ApexXAxis {
        const labelStyles = {
            style: this.labelStyle,
            rotate: -45,
            rotateAlways: true,
            trim: false,
            offsetY: -5,
            datetimeUTC: false,
            format: this.dateFormats[data.dateRange.rangeMode],
        };
        const isFullMonth = data.timestamps.length >= 30;

        if (data.dateRange.rangeMode === DateRangeMode.DAY) {
            return {
                type: 'datetime',
                ...this.generateMinMaxRanges(data.dateRange, data.timestamps),
                labels: labelStyles,
            };
        }
        return {
            type: isFullMonth ? 'category' : 'datetime',
            tickAmount: data.timestamps.length - 1,
            ...this.generateMinMaxRanges(data.dateRange, data.timestamps),
            labels: {
                ...labelStyles,
                formatter: isFullMonth
                    ? (value) => {
                          return format(new Date(value), this.dateFormats[data.dateRange.rangeMode]);
                      }
                    : undefined,
            },
        };
    }
}

interface ChartOptions {
    tooltip: ApexTooltip;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    fill: ApexFill;
    legend: ApexLegend;
    grid: ApexGrid;
    plotOptions: ApexPlotOptions;
}
