import { Observable } from 'rxjs';
import { IotFeature } from '../IotFeatures';

export interface IotBackendService {
    getInstallation(installationId: string): Observable<InstallationOverviewResponse>;

    getDeviceFeatures(data: FeatureSubscription): Observable<IotFeature[]>;
    subscribeToDeviceFeaturesUpdates(data: FeatureSubscription[]): Observable<IotFeature[]>;
    getTimeSeriesData(data: TimeSeriesInput): Observable<TimeSeriesResponse>;
}

export interface FeatureSubscription {
    installationId: string;
    gatewayId: string;
    deviceId: string;
    features: string[];
}

export interface InstallationOverviewResponse {
    data: {
        id: number;
        description: string;
        gateways: {
            serial: string;
            version: string;
            devices: vNextDevice[];
        }[];
        aggregatedStatus: string;
    };
}

export interface vNextDevice {
    gatewaySerial: string;
    id: string;
    boilerSerial: string | null;
    createdAt: string;
    editedAt: string;
    modelId: string;
    status: string;
    deviceType: string;
    roles: string[];
}
[];

export interface TimeSeriesInput {
    gatewayId: string;
    deviceId?: string;
    startDate: Date;
    endDate: Date;
    resolution: TimeSeriesResolution;
    properties: readonly TimeSeriesProperties[];
}

export interface TimeSeriesResponse {
    gateway_id: string;
    device_id: string;
    resolution: TimeSeriesResolution;
    timeframe: string;
    summary_properties: TimeSeriesRecordFeaturesUnits;
    timeseries_properties: TimeSeriesRecordFeaturesUnits;
    data: {
        summary: TimeSeriesRecordFeatures;
        timeseries: (TimeSeriesRecordFeatures & { timestamp: string })[];
    };
}

export type TimeSeriesResolution = '5m' | '15m' | '1d' | '1W' | '1M' | '1Y';
export const AllTimeSeriesProperties = <const>[
    'ems.kpi.autarky',
    'ems.kpi.self-consumption',
    'ems.energy.production.total',
    'ems.energy.consumption.total',
    'ems.energy.consumption.heat-pump',
    'ems.energy.consumption.household',
    'ems.energy.consumption.vcs',
    'ems.energy.storage.to.consumption',
    'ems.energy.production.to.storage',
    'ems.carbon.savings',
    'ems.carbon.emissions',
    'ems.carbon.trees',
    'ems.carbon.car.distance',
    'ems.energy.grid.feed-in',
    'ems.energy.grid.to.consumption',
    'ems.energy.storage.charge',
    'ems.energy.storage.discharge',
    'ems.energy.production.to.consumption',
    'ess.stateOfCharge/value',
];

export type TimeSeriesProperties = (typeof AllTimeSeriesProperties)[number];
export type TimeSeriesRecordFeatures = { [key in TimeSeriesProperties]: number };
export type TimeSeriesRecordFeaturesUnits = { [key in TimeSeriesProperties]: TimeSeriesUnits };

export enum TimeSeriesUnits {
    PERCENTAGE = 'percentage',
    KWH = 'kWh',
    KG = 'kg',
}
