import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AssetService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {}
    public getAssetPath(asset: string): string {
        return `${environment.assetBasePath}${asset}`;
    }

    public getSanitizedAssetPath(asset: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(this.getAssetPath(asset));
    }

    public registerIcons(icons: string[]) {
        icons.forEach((icon) => {
            this.matIconRegistry.addSvgIcon(icon, this.getSanitizedAssetPath(`icons/${icon}.svg`));
        });
    }
}
