<div class="flex-container" *ngIf="!hideManual">
    <app-static-content></app-static-content>

    <h3>Show case</h3>
    <p class="mt-3">
        This instance also acts as a test implementation. The various attributes can be tested using the following query
        parameters:
    </p>

    <ul class="mt-3">
        <li ngClass="{'disabled' : mock}">installationid</li>
        <input
            data-testid="demo-installation-input"
            type="text"
            [disabled]="mock$.value"
            [formControl]="installationIdFormControl"
        />
        <li>lang</li>
        <input data-testid="demo-language-input" type="text" [(ngModel)]="lang" />
        <li>theme</li>
        <input data-testid="demo-theme-input" type="text" [(ngModel)]="theme" />
        <li>detail</li>
        <input data-testid="demo-detail-input" type="text" [(ngModel)]="detail" />
    </ul>

    <div class="login-action" *ngIf="!(token | async)">
        <button data-testid="login-button" (click)="login()">You are not logged in. Click here to login</button>
    </div>
    <div *ngIf="mock$.value">
        <button data-testid="disable-mock-mode" (click)="mock$.next(false)">Disable mock mode</button>
    </div>
    <div *ngIf="!mock$.value">
        <button data-testid="enable-mock-mode" (click)="mock$.next(true)">Switch to mock mode</button>
    </div>
</div>

<app-simulation-control *ngIf="mock$.value && !hideSimulation" #simulationControl></app-simulation-control>

<div class="component-placeholder" *ngIf="ready">
    <vi-energy-cockpit
        [installationId]="installationIdFormControl.value"
        [theme]="theme"
        [lang]="lang"
        [detail]="detail"
        [mock]="mock$.value ? mockedBackend : undefined"
    >
    </vi-energy-cockpit>
    <vi-data-analytics
        *ngIf="detail === 'full'"
        [mock]="mock$.value ? mockedBackend : undefined"
        [lang]="lang"
        [gatewayId]="(data$ | async)?.data?.devices?.gatewayId"
        [batteryDeviceId]="(data$ | async)?.data?.devices?.batteryDeviceId"
    ></vi-data-analytics>
</div>
