import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from 'rxjs';
import { LoadingStatus, Theme } from '../../parameterTypes';
import { EnergyTableItem, EnergyTableItemCategory } from '../../services/data.service';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

const LOTTIE_LIGHT = 'lottie/lottie_anim_energy_cockpit_light.json';
const LOTTIE_DATK = 'lottie/lottie_anim_energy_cockpit_dark.json';

@Component({
    selector: 'app-house-animation',
    templateUrl: './house-animation.component.html',
    styleUrls: ['./house-animation.component.scss'],
})
export class HouseAnimationComponent {
    @Input() status!: LoadingStatus;

    @Input() state!: EnergyTableItem[];

    @Input() set theme(theme: Theme | undefined) {
        this.theme$.next(theme || Theme.LIGHT);
    }
    theme$ = new BehaviorSubject(Theme.LIGHT);

    public options: Observable<AnimationOptions> = this.theme$.pipe(
        switchMap((theme) => this.getLottieFile(theme)),
        map((file) => ({ animationData: file })),
        shareReplay(),
    );

    constructor(
        public assetService: AssetService,
        private http: HttpClient,
    ) {}

    get lottieContainerClass() {
        return this.state
            .flatMap((item) => {
                const itemClasses = [`${item.type}-available`];
                if (item.value > 0) {
                    const type = item.category === EnergyTableItemCategory.DELIVERED ? 'production' : 'consumption';
                    itemClasses.push(`${item.type}-${type}`);
                }
                return itemClasses;
            })
            .join(' ');
    }

    getLottieFile(theme: Theme) {
        return this.http
            .get<{
                layers: LottieLayer[];
            }>(this.assetService.getAssetPath(theme === Theme.LIGHT ? LOTTIE_LIGHT : LOTTIE_DATK))
            .pipe(
                map((response) => {
                    return {
                        ...response,
                        layers: response.layers.map(
                            (layer) => <LottieLayer>{ ...layer, cl: this.generateLottieLayerClassName(layer) },
                        ),
                    };
                }),
            );
    }

    private generateLottieLayerClassName(layer: LottieLayer) {
        return `svgLayer ${layer.nm.split('-')[0]}`;
    }
}

interface LottieLayer {
    nm: string;
    cl: string;
}

export interface HouseAnimationState {
    vitoChargeAvailable: boolean;
    viessmannHeatPumpAvailable: boolean;
    viessmannPvAvailable: boolean;
    viessmannWallboxAvailable: boolean;
    isPvProducing: boolean;
    isGridFeedIn: boolean;
    isGridConsumption: boolean;
    isHouseConsumption: boolean;
    isCarCharging: boolean;
    isBatteryCharging: boolean;
    isBatteryDischarging: boolean;
    isHeatPumpConsumingEnergy: boolean;
}
