import { Component, Input } from '@angular/core';
import {
    ChartDataService,
    ConsumptionType,
    EnergyChartItem,
    EnvironmentStatistics,
} from 'src/app/modules/data-analytics/services/chart-data.service';
import { TimeSeriesResponse } from 'src/app/modules/shared/services/iot-backend/iot-backend.service';

@Component({
    selector: 'pie-charts-statistics',
    templateUrl: './pie-charts-statistics.component.html',
    styleUrls: ['./pie-charts-statistics.component.scss'],
})
export class PieChartStatisticsComponent {
    ConsumptionType = ConsumptionType;

    status: 'default' | 'pending' | 'error' = 'pending';

    public consumptionChartItems: EnergyChartItem[] = [];
    public autarkyChartItems: EnergyChartItem[] = [];
    public environmentStatistics: EnvironmentStatistics | null = null;
    public consumptionChartValue = 0;
    public autarkyChartValue = 0;

    constructor(private chartDataService: ChartDataService) {}
    @Input() set timeSeriesResponse(timeSeriesResponse: TimeSeriesResponse | undefined) {
        if (timeSeriesResponse) {
            this.convertChartData(<TimeSeriesResponse>timeSeriesResponse);
            this.status = 'default';
            return;
        }
        this.status = 'pending';
    }

    private convertChartData(timeSeriesResponse: TimeSeriesResponse) {
        const chartData = this.chartDataService.getChartData(timeSeriesResponse);
        const environment = this.chartDataService.getEnvironmentStatistics(timeSeriesResponse);
        const { autarky, selfConsumption } = this.chartDataService.getEnergyKpiValues(timeSeriesResponse);
        this.autarkyChartValue = autarky;
        this.consumptionChartValue = selfConsumption;

        if (chartData.length) {
            this.consumptionChartItems = chartData.filter(
                (item) => item.consumptionType === ConsumptionType.SELF_CONSUMPTION,
            );
            this.autarkyChartItems = chartData.filter((item) => item.consumptionType === ConsumptionType.AUTARKY);

            this.environmentStatistics = environment;
        }
    }
}
