import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConsumptionType, EnergyChartItem } from '../../services/chart-data.service';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

@Component({
    selector: 'app-energy-dialog',
    templateUrl: './energy-dialog.component.html',
    styleUrls: ['./energy-dialog.component.scss'],
})
export class EnergyDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { series: EnergyChartItem[]; type: ConsumptionType },
        private translate: TranslateService,
        public assetService: AssetService,
    ) {}

    energyItems: EnergyChartItem[] = [];
    gridItems: EnergyChartItem[] = [];
    energySummary: { total: { value: number; unit: string }; self: { value: number; unit: string } } = {
        total: { value: 0, unit: 'kWh' },
        self: { value: 0, unit: 'kWh' },
    };

    ngOnInit(): void {
        this.energyItems = this.data.series.filter((item) => item.type !== 'grid');
        this.gridItems = this.data.series.filter((item) => item.type === 'grid');

        this.energySummary.total.value = this.sum(this.data.series);
        this.energySummary.self.value = this.sum(this.energyItems);

        this.energySummary.total.unit = this.translate.instant(
            `DATA_ANALYTICS.UNITS.${this.data.series[0]?.unit || 'kWh'}`,
        );
        this.energySummary.self.unit = this.translate.instant(
            `DATA_ANALYTICS.UNITS.${this.energyItems[0]?.unit || 'kWh'}`,
        );
    }

    sum(items: EnergyChartItem[]): number {
        return Number(
            items
                .map((item) => item.value)
                .reduce((partialSum, a) => partialSum + a, 0)
                .toFixed(1),
        );
    }
}
