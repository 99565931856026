import { Component, Input, TrackByFunction } from '@angular/core';
import { LoadingStatus } from '../../../shared/parameterTypes';
import { EnergyTableItem, EnergyTableItemCategory } from '../../../shared/services/data.service';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

@Component({
    selector: 'app-energy-table',
    templateUrl: './energy-table.component.html',
    styleUrls: ['./energy-table.component.scss'],
})
export class EnergyTableComponent {
    @Input() type!: EnergyTableItemCategory;

    @Input() batteryCharge: number | null = null;

    @Input() tableItems: EnergyTableItem[] = [];

    @Input() status: LoadingStatus = 'pending';

    constructor(public assetService: AssetService) {}

    public trackByEnergyItem: TrackByFunction<EnergyTableItem> = (index, item) => item.type + item.category;
}
