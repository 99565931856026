import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPowerUnits' })
export class FormatUnitsPipe implements PipeTransform {
    transform({ value, unit }: { value: number; unit: string }): string {
        if (unit === 'watt') {
            if (value >= 1000) {
                return `${(value / 1000).toFixed(1)} kW`;
            }
            return `${Math.floor(value)} W`;
        }

        if (unit === 'kWh') {
            return `${value.toFixed(2)} kWh`;
        }

        return `${value} ${unit}`;
    }
}
