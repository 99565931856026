import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnvironmentStatistics } from 'src/app/modules/data-analytics/services/chart-data.service';
import { AssetService } from 'src/app/modules/shared/services/asset.service';

@Component({
    selector: 'app-environments-dialog',
    templateUrl: './environment-dialog.component.html',
    styleUrls: ['./environment-dialog.component.scss'],
})
export class EnvironmentDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EnvironmentStatistics,
        public assetService: AssetService,
    ) {}
}
