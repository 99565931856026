<div class="container">
    <div class="flex-container" [ngClass]="detail" *ngIf="(status$ | async) !== 'error'">
        <div class="flex-row" *ngIf="fullDetail">
            <h3>{{ 'ENERGY_COCKPIT.BASE_COMPONENT.TITLE' | translate }}</h3>
        </div>
        <div class="flex-row">
            <div class="flex-child">
                <div class="flex-row">
                    <div class="house-animation" [class.mb-5]="(status$ | async) === 'default'">
                        <app-house-animation
                            [status]="(status$ | async) || 'pending'"
                            [theme]="theme"
                            [state]="(data$ | async)?.data?.tableData || []"
                        />
                    </div>
                </div>
            </div>
            <div class="flex-child" *ngIf="fullDetail">
                <div>
                    <app-energy-table
                        [status]="(status$ | async) || 'pending'"
                        [tableItems]="(autarkyTableItems$ | async) || []"
                        [type]="EnergyTableItemCategory.DELIVERED"
                        [batteryCharge]="(data$ | async)?.data?.batteryCharge || null"
                    ></app-energy-table>
                </div>
                <div>
                    <app-energy-table
                        [status]="(status$ | async) || 'pending'"
                        [tableItems]="(consumptionTableItems$ | async) || []"
                        [type]="EnergyTableItemCategory.RECEIVED"
                        [batteryCharge]="(data$ | async)?.data?.batteryCharge || null"
                    ></app-energy-table>
                </div>
            </div>
        </div>
        <div class="flex-row flex-no-wrap">
            <div class="flex-child">
                <div class="flex-container-chart">
                    <basic-energy-circle-chart
                        data-testid="energy-cockpit-autarky-chart-component"
                        [value]="(data$ | async)?.data?.chartsKpi?.[ConsumptionType.AUTARKY] || 0"
                        color="#2CC84D"
                        [status]="(status$ | async) || 'pending'"
                        [textLabel]="'ENERGY_COCKPIT.ENERGY_CHART.BUTTON_LABEL.' + ConsumptionType.AUTARKY | translate"
                    ></basic-energy-circle-chart>
                </div>
            </div>
            <div class="flex-child">
                <div class="flex-container-chart">
                    <basic-energy-circle-chart
                        data-testid="energy-cockpit-self-consumption-chart-component"
                        [value]="(data$ | async)?.data?.chartsKpi?.[ConsumptionType.SELF_CONSUMPTION] || 0"
                        color="#FBC640"
                        [status]="(status$ | async) || 'pending'"
                        [textLabel]="
                            'ENERGY_COCKPIT.ENERGY_CHART.BUTTON_LABEL.' + ConsumptionType.SELF_CONSUMPTION | translate
                        "
                    ></basic-energy-circle-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex-column h-100 d-flex justify-content-center align-items-center" *ngIf="(status$ | async) === 'error'">
    <app-no-data></app-no-data>
</div>
