<div class="month-year-datepicker" [ngClass]="{ disabled }">
    <button
        data-testid="date-button-left"
        mat-stroked-button
        [disabled]="disabled || !canJumpPrevious()"
        class="button-left"
        (click)="setPrevious()"
    >
        <mat-icon svgIcon="chevron_left"></mat-icon>
    </button>

    <mat-form-field class="date-picker">
        <mat-label>{{ 'DATA_ANALYTICS.CONTROLS.DATE_RANGE.SELECT_DATE' | translate }}</mat-label>
        <div data-testid="datepicker-content" class="formatted-date" (click)="!disabled && picker.open()">
            <span>{{ date.value && dateService.formatWithLocale(date.value, dateFormat) }} </span>
            <mat-icon svgIcon="expand_more"></mat-icon>
        </div>

        <input matInput [matDatepicker]="picker" [formControl]="date" hidden [min]="minDate" [max]="maxDate" />

        <mat-datepicker-toggle data-testid="datepicker-toggle" matIconSuffix [disabled]="disabled" [for]="picker">
            <mat-icon svgIcon="date_range" matDatepickerToggleIcon></mat-icon>
        </mat-datepicker-toggle>

        <mat-datepicker
            #picker
            [startView]="startView"
            (monthSelected)="onMonthSelected($event, picker)"
            (yearSelected)="onYearSelected($event, picker)"
        ></mat-datepicker>
    </mat-form-field>
    <button
        data-testid="date-button-right"
        mat-stroked-button
        [disabled]="disabled || !canJumpNext()"
        class="button-right"
        (click)="setNext()"
    >
        <mat-icon svgIcon="chevron_right"></mat-icon>
    </button>
</div>
