import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataAnalyticsModule } from '../data-analytics/data-analytics.module';
import { EnergyCockpitModule } from '../energy-cockpit/energy-cockpit.module';
import { DemoPageComponent } from './demo-page.component';
import { SimulationControlComponent } from './simulation-control/simulation-control.component';
import { StaticContentComponent } from './static-content/static-content.component';

@NgModule({
    declarations: [DemoPageComponent, SimulationControlComponent, StaticContentComponent],
    imports: [
        CommonModule,
        EnergyCockpitModule,
        FormsModule,
        MatSlideToggleModule,
        MatCardModule,
        NoopAnimationsModule,
        MatGridListModule,
        MatCheckboxModule,
        MatInputModule,
        ReactiveFormsModule,
        RouterModule.forRoot([]),
        DataAnalyticsModule,
        TranslateModule,
    ],
    exports: [DemoPageComponent],
})
export class DemoPageModule {}
