<div class="flex-row flex-fill body">
    <div class="flex-child align-items-center">
        <ng-container *ngIf="status === 'default'; else skeleton">
            <div
                [attr.data-testid]="'energy-cockpit-' + textLabel?.toLowerCase()?.split(' ')?.join('-') + '-chart'"
                class="donut"
                [style.--chartItemGradientAngle]="chartItemGradientAngle$ | async"
                [style.--chartColor]="color"
            >
                <div class="hole">
                    <span
                        [attr.data-testid]="
                            'energy-cockpit-' + textLabel?.toLowerCase()?.split(' ')?.join('-') + '-chart-value'
                        "
                        >{{ value$ | async }}%</span
                    >
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="flex-row flex-fill flex-column-reverse body" *ngIf="textLabel">
    <div class="flex-child justify-content-center">
        <span
            [attr.data-testid]="'energy-cockpit-' + textLabel.toLowerCase().split(' ').join('-') + '-chart-label'"
            class="text-label"
            [ngClass]="status"
        >
            {{ textLabel }}
        </span>
    </div>
</div>

<ng-template #skeleton>
    <ngx-skeleton-loader [appearance]="'custom-content'">
        <img class="placeholder-img" [src]="assetService.getAssetPath('images/chart_skeleton.svg')" />
    </ngx-skeleton-loader>
</ng-template>
