<ng-lottie
    [hidden]="status !== 'default'"
    [options]="options | async"
    class="lottie-animation"
    [containerClass]="lottieContainerClass"
>
</ng-lottie>
<ngx-skeleton-loader *ngIf="status === 'pending'" [appearance]="'custom-content'">
    <img class="placeholder-img" [src]="assetService.getAssetPath('images/energy_flow_skeleton.svg')" />
</ngx-skeleton-loader>
