import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DataAnalyticsModule } from '../data-analytics/data-analytics.module';
import { HttpInterceptorService } from '../shared/services/http.interceptor';
import { SharedModule } from '../shared/shared.module';
import { EnergyTableComponent } from './components/energy-table/energy-table.component';
import { EnergyCockpitComponent } from './energy-cockpit.component';

@NgModule({
    declarations: [EnergyCockpitComponent, EnergyTableComponent],
    imports: [
        CommonModule,
        BrowserModule,
        MatButtonModule,
        TranslateModule.forChild(),
        MatCardModule,
        NgApexchartsModule,
        SharedModule,
        MatIconModule,
        AngularSvgIconModule,
        MatDialogModule,
        NgxSkeletonLoaderModule,
        DataAnalyticsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
    ],
    exports: [EnergyCockpitComponent],
})
export class EnergyCockpitModule {}
