<div class="headline-section body">
    <div class="d-flex flex-column pt-1">
        <h2 data-testid="analysis-environment-title" class="mb-0">
            {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.TITLE' | translate }}
        </h2>
    </div>
    <button data-testid="analysis-environment-close-button" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="close" class="close-icon"></mat-icon>
    </button>
</div>

<div class="dialog-content body">
    <div class="container">
        <div class="flex-row">
            <div class="dialog-img">
                <svg-icon
                    data-testid="analysis-environment-dialog-img"
                    [src]="assetService.getAssetPath('images/environment.svg')"
                />
            </div>
            <div class="dialog-info">
                <div class="flex-row justify-space-between mb-3 value-title">
                    <p data-testid="analysis-environment-co2-savings-text">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.SAVINGS_TEXT' | translate }}
                    </p>
                    <p data-testid="analysis-environment-co2-savings-value">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.SAVINGS_VALUE' | translate: data.co2Savings }}
                    </p>
                </div>
                <div class="flex-row justify-space-between mb-4 value-description">
                    <p data-testid="analysis-environment-co2-savings-description">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.SAVINGS_DESCRIPTION' | translate: data.treesSaved }}
                    </p>
                </div>
                <div class="flex-row justify-space-between mb-3 value-title">
                    <p data-testid="analysis-environment-co2-emissions-text">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.EMISSIONS_TEXT' | translate }}
                    </p>
                    <p data-testid="analysis-environment-co2-emissions-value">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.EMISSIONS_VALUE' | translate: data.co2Emission }}
                    </p>
                </div>
                <div class="flex-row justify-space-between mb-4 value-description">
                    <p data-testid="analysis-environment-co2-emissions-description">
                        {{ 'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.CO2.EMISSIONS_DESCRIPTION' | translate: data.carTravel }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row hint">
        <mat-icon svgIcon="info" class="icon"></mat-icon>
        <p
            data-testid="analysis-environment-info-text"
            class="env-hint"
            [innerHTML]="'ENERGY_COCKPIT.ENVIRONMENT_DIALOG.HINT' | translate"
        ></p>
    </div>
</div>
