import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import * as DateFns from 'date-fns';
import { de, enGB } from 'date-fns/locale';

@Injectable({
    providedIn: 'root',
    deps: [],
})
export class DateService {
    constructor(private dateAdapter: DateAdapter<DateFnsAdapter>) {}
    private locale: DateFns.Locale = enGB;

    public setLocale(locale: 'en' | 'de') {
        if (locale === 'de') {
            this.locale = de;
            this.dateAdapter.setLocale(de);
            return;
        }
        this.locale = enGB;
        this.dateAdapter.setLocale(enGB);
    }

    public formatWithLocale(date: Date, format: string) {
        return DateFns.format(date, format, { locale: this.locale });
    }
}
