import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { DemoPageComponent } from './modules/demo-page/demo-page.component';
import { DemoPageModule } from './modules/demo-page/demo-page.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `${environment.energyCockpitConfig.translationsBaseUrl}/phraseapp-proxy/projects/45979c1187d6666445de92406ae12a32/locales/`,
        '/download?file_format=i18next',
    );
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            isolate: false,
        }),
        DemoPageModule,
    ],
    bootstrap: [DemoPageComponent],
})
export class AppModule {}
