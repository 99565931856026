import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DateFnsModule, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { de } from 'date-fns/locale';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { ChartComponent } from './components/chart/chart.component';
import { ControlsComponent } from './components/controls/controls.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { EnergyCircleChartComponent } from './components/energy-circle-chart/energy-circle-chart.component';
import { EnergyDialogComponent } from './components/energy-dialog/energy-dialog.component';
import { EnergyDialogOpenButtonComponent } from './components/energy-dialog-open-button/energy-dialog-open-button.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { EnvironmentDialogComponent } from './components/environment-dialog/environment-dialog.component';
import { ParametersSelectDialogComponent } from './components/parameters-select-dialog/parameters-select-dialog.component';
import { PieChartStatisticsComponent } from './components/pie-charts-statistics/pie-charts-statistics.component';
import { ChartTableComponent } from './components/table/chart-table.component';
import { DataAnalyticsComponent } from './data-analytics.component';

const materialModules = [
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
];

@NgModule({
    declarations: [
        DataAnalyticsComponent,
        ChartComponent,
        DatePickerComponent,
        ChartTableComponent,
        ParametersSelectDialogComponent,
        PieChartStatisticsComponent,
        EnvironmentComponent,
        ControlsComponent,
        EnvironmentDialogComponent,
        EnergyCircleChartComponent,
        EnergyDialogComponent,
        EnergyDialogOpenButtonComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        TranslateModule.forChild(),
        AngularSvgIconModule,
        NgApexchartsModule,
        DateFnsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NoopAnimationsModule,
        NgxSkeletonLoaderModule,
        ...materialModules,
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: de },
    ],
    exports: [DataAnalyticsComponent],
})
export class DataAnalyticsModule {}
