<div class="body">
    <h1>Viessmann Energy Cockpit</h1>

    <p>
        The Energy Cockpit is available as
        <a href="https://developer.mozilla.org/de/docs/Web/Web_Components">web component</a>. The web component can be
        used in any web application.
    </p>

    <h2 id="projectstages" class="mt-3">Project stages</h2>

    <p>There are 3 project stages</p>

    <ul>
        <li>
            development =
            <a href="https://energy-cockpit-integration.viessmann.com/"
                >https://energy-cockpit-integration.viessmann.com/</a
            >
        </li>

        <li>
            staging =
            <a href="https://energy-cockpit-staging.viessmann.com/">https://energy-cockpit-staging.viessmann.com/</a>
        </li>

        <li>prod = <a href="https://energy-cockpit.viessmann.com/">https://energy-cockpit.viessmann.com/</a></li>
    </ul>

    <h3>Web Component</h3>

    <p>Add following code to your index.html header:</p>

    <pre><code>&lt;head&gt;
...

&lt;script defer src="https://energy-cockpit-integration.viessmann.com/elements.js"&gt;&lt;/script&gt;
&lt;link rel="stylesheet" type="text/css" href="https://energy-cockpit-integration.viessmann.com/styles.css"&gt;

...
&lt;/head&gt;
</code></pre>

    <h2 id="componentusage">Component usage</h2>

    <h4>Energy Cockpit Component</h4>

    <pre><code>&lt;vi-energy-cockpit&gt;&lt;/vi-energy-cockpit&gt;</code></pre>

    <h4>Authentication</h4>

    <p class="mb-3">
        The component requires a valid CSRF token to access the installation details. The token can be passed via a
        global variable "csrfToken".
    </p>

    <h4>Inputs</h4>

    <ul>
        <li>installation-id: ID of the installation whose data is to be loaded (mandatory)</li>

        <li>
            theme: The "theme" attribute determines the appearance of the Lottie animation. Default value is "light"
            alternatively "dark" is supported
        </li>

        <li>
            detail: The "detail" attribute determines the level of detail of the component. Default value is "full".
            Alternatively, "minimal" is possible.
        </li>

        <li>
            lang: The "lang" attribute determines the used language of the component. Default value is "de".
            Alternatively, "en" is possible.
        </li>
    </ul>

    <pre><code>&lt;vi-energy-cockpit installation-id="123" theme="dark" detail="minimal" lang="en" &gt;&lt;/vi-energy-cockpit&gt;</code></pre>

    <h4>Outputs</h4>

    <p class="mb-3">This component fires an event on each status change named "statusChange". Possible values are:</p>

    <ul>
        <li>LOADING</li>
        <li>ERROR</li>
        <li>NO_DATA</li>
        <li>READY</li>
    </ul>

    <pre>
    <code>{{code}}</code>
  </pre>
</div>
