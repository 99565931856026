/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { io } from 'socket.io-client';
import { IotFeature } from '../IotFeatures';
import { FeatureSubscription } from './iot-backend.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LiveUpdateService {
    constructor(private http: HttpClient) {}

    createConnection(featureSubscriptions: FeatureSubscription[]) {
        const subscriptions: LiveUpdateSubscription[] = featureSubscriptions.map(
            ({ deviceId, features, gatewayId }) => ({
                type: 'device-features',
                gatewayId,
                id: deviceId,
                values: features,
            }),
        );
        return this.http.post<CreateLiveUpdateResponse>(
            `${environment.energyCockpitConfig.baseUrl}/live-updates/v1/iot`,
            { subscriptions },
        );
    }
    subscribeToLiveUpdates(featureSubscriptions: FeatureSubscription[]) {
        return this.createConnection(featureSubscriptions).pipe(
            switchMap((socketInfo) => {
                const socket = io(`${socketInfo.url}`, {
                    path: socketInfo.path,
                    transports: ['websocket'],
                    reconnection: true,
                });
                socket.io.open();

                return new Observable<IotFeature>((observer) => {
                    socket.on('feature', (e: LiveUpdateFeatureEvent) => observer.next(e.feature));
                    socket.on('error', (e) => observer.error(e));
                    socket.on('disconnect', () => observer.complete());
                });
            }),
        );
    }
}

interface LiveUpdateSubscription {
    type: 'device-features' | 'gateway-features';
    id: string;
    gatewayId: string;
    values: string[];
}
export interface CreateLiveUpdateResponse {
    path: string;
    url: string;
    validTo: string;
    namespace: string;
    socketId: string;
}

interface LiveUpdateFeatureEvent {
    event: 'feature.changed';
    feature: IotFeature;
    reason: string;
}
