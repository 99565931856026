/* eslint-disable @everest/no-useless-undefined */
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    switchMap,
} from 'rxjs';
import { EnergyCockpitStatus, LoadingStatus, Theme } from '../shared/parameterTypes';
import { AssetService } from '../shared/services/asset.service';
import {
    ConsumptionType,
    DataService,
    EnergyCockpitData,
    EnergyTableItem,
    EnergyTableItemCategory,
} from '../shared/services/data.service';
import { DateService } from '../shared/services/date.service';
import { HttpIotBackendService } from '../shared/services/iot-backend/http-iot-backend.service';
import { IotBackendService } from '../shared/services/iot-backend/iot-backend.service';

@Component({
    selector: 'vi-energy-cockpit',
    templateUrl: './energy-cockpit.component.html',
    styleUrls: ['./energy-cockpit.component.scss'],
})
export class EnergyCockpitComponent implements AfterViewInit {
    @Input() detail: 'minimal' | 'full' = 'full';

    @Input() set installationId(installationId: string | undefined) {
        this.installationId$.next(installationId);
    }

    @Input() set mock(mock: IotBackendService | undefined) {
        this.mock$.next(mock);
    }

    @Input() set lang(lang: string | undefined) {
        if (lang) {
            this.translateService.use(lang);
            if (lang.toLowerCase().startsWith('en')) {
                this.dateService.setLocale('en');
            } else {
                this.dateService.setLocale('de');
            }
        }
    }
    @Input() theme: Theme | undefined;
    @Output() statusChange: EventEmitter<EnergyCockpitStatus> = new EventEmitter<EnergyCockpitStatus>();
    installationId$ = new BehaviorSubject<string | undefined>(undefined);
    mock$ = new BehaviorSubject<IotBackendService | undefined>(undefined);

    EnergyTableItemCategory = EnergyTableItemCategory;
    ConsumptionType = ConsumptionType;

    get fullDetail() {
        return this.detail === 'full';
    }

    constructor(
        private translateService: TranslateService,
        private iotService: DataService,
        public assetService: AssetService,
        public iotBackendService: HttpIotBackendService,
        private dateService: DateService,
    ) {
        translateService.setDefaultLang(this.lang || 'de');
    }

    data$: Observable<{
        data?: EnergyCockpitData;
        status: LoadingStatus;
    }> = combineLatest([this.installationId$.pipe(startWith(null)), this.mock$.pipe(startWith(undefined))]).pipe(
        filter(([installationId, mock]) => !!mock || !!installationId),
        switchMap(([installationId, mock]) =>
            this.iotService.loadData(installationId || '', mock).pipe(
                map((data) => ({ data, status: <const>'default' })),
                startWith({ status: <const>'pending' }),
                catchError(() => of({ status: <const>'error' })),
            ),
        ),
        shareReplay(1),
    );

    consumptionTableItems$ = this.data$.pipe(
        map(({ data }) =>
            data?.tableData
                .filter((item) => item.category === EnergyTableItemCategory.RECEIVED)
                .sort(this.keepGridAtTheEndOfList),
        ),
    );

    autarkyTableItems$ = this.data$.pipe(
        map(({ data }) =>
            data?.tableData
                .filter((item) => item.category === EnergyTableItemCategory.DELIVERED)
                .sort(this.keepGridAtTheEndOfList),
        ),
    );

    status$ = <Observable<LoadingStatus>>this.data$.pipe(
        map(({ status }) => status),
        startWith(<const>'pending'),
    );

    ngAfterViewInit(): void {
        this.statusChange.emit(EnergyCockpitStatus.LOADING);
        this.status$.pipe(distinctUntilChanged()).subscribe((status) => {
            if (status === 'default') {
                this.statusChange.emit(EnergyCockpitStatus.READY);
            }
            if (status === 'error') {
                this.statusChange.emit(EnergyCockpitStatus.ERROR);
            }
        });
    }

    private keepGridAtTheEndOfList(item1: EnergyTableItem, item2: EnergyTableItem): number {
        if (item1.type === 'Grid') {
            return item2.type === 'Grid' ? 0 : 1;
        }
        return -1;
    }
}
